import React, { useEffect, useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    Button,
    Flex,
    Text,
    Textarea,
    Spinner,
    Center
} from '@chakra-ui/react';
import { useApp } from '../../context';

const EditReportModal = ({open, close, report}) => {
    const [mainLoading, setMainLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [reportName, setReportName] = useState('');
    const [headers, setHeaders] = useState('');
    const [query, setQuery] = useState('');

    const app = useApp();

    useEffect(() => {
        setReportName('');
        setHeaders('');
        setQuery('');
        setLoading(false);
        setSuccess(false);
        setError(null);

        if(report){
            setMainLoading(true);
            fetchMetaData();
        }
    }, [report])

    const fetchMetaData = async () => {
        const result = await app.helloFambase(
            'POST',
            'dashboard/get-dynamic-report-meta-data',
            {
                key: report
            }
        )

        setMainLoading(false);

        if(result.success){
            setReportName(result.data.name);
            setHeaders(result.data.headers);
            setQuery(result.data.query);
        }
    }

    const submitHandler = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);

        if(!reportName && !headers && !query){
            setError('Please fill all the fields');
            setLoading(false);
            return;
        }

        const data = {
            key: report,
            name: reportName,
            headers: headers,
            query: query
        };

        const result = await app.helloFambase(
            'POST',
            'dashboard/edit-dynamic-report',
            data
        )

        setLoading(false);

        if(result.success) setSuccess(true);
        else setError('Error in adding/updating report.');
        
    }

    return(
        <>
            <Modal size="5xl" isOpen={open} onClose={close}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{report ? 'Edit Report' : 'Add Report'}</ModalHeader>
                    <ModalCloseButton />
                    
                    {mainLoading ? 
                        <Center>
                            <Spinner mt="10" mb="10" size="xl" /> 
                        </Center>
                    :
                        <ModalBody mt="10" mb="10">
                            <Flex align="center">
                                <Text mr="5">Report Name</Text>
                                <Input value={reportName} onChange={(e) => setReportName(e.target.value)} width="50%" type="text" placeholder="Report Name" /> 
                            </Flex>

                            <Flex align="center" mt="10">
                                <Text mr="5.5%">Headers</Text>
                                <Input value={headers} onChange={(e) => setHeaders(e.target.value)} width="50%" type="text" placeholder="Headers" />
                            </Flex>

                            <Flex align="center" mt="10">
                                <Text mr="7%">Query</Text>
                                <Textarea value={query} onChange={(e) => setQuery(e.target.value)} width="50%" type="text" placeholder="Query" />
                            </Flex>

                            <Button mb="10" mt="10" colorScheme="red" onClick={submitHandler}>{loading ? <Spinner /> : 'Submit'}</Button>

                            {
                                error ? 
                                    <Text color="red.500">{error}</Text> 
                                :
                                    success ?
                                        <Text color="green.500">Report added/edited successfully</Text>
                                        :
                                        null
                            }
                        
                        </ModalBody>
                    }
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditReportModal;