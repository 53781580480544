import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

function UserViewCertificate({ link }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {link && (
                <Button size="xs" onClick={onOpen} colorScheme="orange">
                    View Certificate
                </Button>
            )}
            <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={3}>
                        <ModalHeader p="0 0.5rem 1rem">
                            Certificate Preview
                            <ModalCloseButton variant="red" />
                        </ModalHeader>
                        <object
                            data={
                                link +
                                '#toolbar=0&navpanes=0&scrollbar=0&zoom=Fit'
                            }
                            onError={e => console.table(e)}
                            width="100%"
                            height="100%"
                            style={{
                                aspectRatio: '69.83 / 49.38',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            type="application/pdf"
                        >
                            <Text
                                textAlign="center"
                                fontSize={20}
                                fontWeight={500}
                            >
                                Unable to preview certificate.
                                <br />
                                <Link
                                    href={link}
                                    color="blue.500"
                                    fontWeight={500}
                                    textDecoration="underline"
                                    download={link.split('/').at(-1)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Click here to Download
                                </Link>
                            </Text>
                        </object>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default UserViewCertificate;
