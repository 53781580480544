
import React, { useState, useEffect } from 'react';

import {
    Center, Box, Select, Flex,
    Heading, Text, Button, Spinner,
    RadioGroup, Radio, Stack,
    Input

} from '@chakra-ui/react';

import { useApp } from '../context';
// import ViewTemplateModal from '../components/MailTemplates/ViewTemplateModal';
// import EditVariablesModal from '../components/Email/EditVariablesModal';

const Whatsapp = () => {
    const app = useApp();
    const [currentType, setCurrentType] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [wappSchedule, setWappSchedule] = useState('');
    const [sendingWapp, setSendingWapp] = useState(false);
    const [message, setMessage] = useState('');
    const [queryData, setQueryData] = useState([]);
    const [queryParams, setQueryParams] = useState({});

    const handleOptionChange = async (type) => {
        const reqd = options.filter(opt => opt.templateName === type)[0];
        if(reqd){
            setCurrentType(reqd.templateName);
            if(reqd.queryData !== null) setQueryData(reqd.queryData.split(','));
            else setQueryData([]);
        } else{
            setCurrentType('');
            setQueryData([]);
        }
        setQueryParams({}); 

    }
    // const getTemplateData = async (id) => {
    //     try{
    //         setLoading(true);

    //         const result = await app.helloFambase(
    //             'GET',
    //             `dashboard/get-template-data/${id}`
    //         )

    //         setLoading(false);

    //         if(result.success){
    //             setTemplateData(result.data);
    //             setCurrentTemplate(result.data.template);

    //             setWappSchedule('');
    //             setMessage(null);
    //         }
    //     } catch(err){
    //         console.log(err);
    //     }
    // }

    useEffect(() => {
        const getOptions = async () => {
            try{
                setLoading(true);
                let response = await app.helloFambase(
                    'GET',
                    'dashboard/get-all-whatsapp-templates',
                    //'dashboard/broadcastEmailOptions'
                );

                if (response.success) {
                    // console.log(response);
                    setOptions(response.data);
                }
                setLoading(false);
            }catch(e){
                console.log(e);
            }
            
        }

        
        getOptions();
        
        
    }, [])

    const submitHandler = async () => {
        try{
            setSendingWapp(true);
            setMessage('');

            let obj = {
                waTemplateName: currentType,
                queryData: {...queryParams}
            }

            let blankFields = false;
            queryData.forEach(data => {
                if(!queryParams[`${data}`] || queryParams[`${data}`] === '') {
                    alert("Please fill all the query data fields");
                    blankFields = true;
                    setSendingWapp(false);
                }
            })

            let tooSoon = false;
            if(wappSchedule !== ''){
                const newSchedMs = (new Date(wappSchedule)).getTime();
                const current = (new Date()).getTime();
                let delayInMins = -1*Math.floor((current - newSchedMs)/60000)
                console.log(delayInMins)
                if(delayInMins < 30) {
                    tooSoon = false;
                    alert("Please schedule atleast 30 mins after current time");
                    setSendingWapp(false);
                    setWappSchedule('');
                }
            }

            if(!blankFields && !tooSoon){
                if(wappSchedule !== '') obj.queryData["scheduleTime"] = new Date(wappSchedule).toISOString();

                console.log(obj);

                const result = await app.helloFambase(
                    'POST',
                    'dashboard/send-adhoc-whatsapp',
                    obj
                )

                setSendingWapp(false);

                if(result.success){
                    if(wappSchedule === '') setMessage('WhatsApp sent successfully.');
                    else setMessage('WhatsApp scheduled successfully.')
                }
            }

        } catch(err){
            console.log(err);
            setSendingWapp(false);

            setMessage('Something went wrong. Please try again later.')
        }
    }

    return (
        <Box
            h="100%"
            overflow="auto"
            pr="10"
        >
            <Flex direction="column" h="100%">
                <Heading mx="auto" size="lg">
                    Send WhatsApp
                </Heading>
                {!loading && 
                    <Center>
                        <Select mt="4" placeholder="Select option" width="40%" onChange={e => handleOptionChange(e.target.value)} size="lg">
                            {
                                options.map((option, index) => {
                                    return (
                                        <option key={index} value={option.templateName}>{option.templateName}</option>
                                    )
                                })
                            }
                        </Select>
                    </Center>
                }
                {loading ? 
                    <Center>
                        <Spinner mt="5"></Spinner>
                    </Center> 
                :
                    <>
                        {currentType !== "" &&
                            <>
                                <Flex mt="10" justify="space-between">
                                    <Text fontSize="2xl">Template - {currentType}</Text>
                                    {/* <Button onClick={() => setViewTemplate(true)} colorScheme="yellow">View Template</Button> */}
                                </Flex>

                                {/* <Flex>
                                    <Button width="60" onClick={() => setEditVariables(true)} mt="10" mb="10" colorScheme="red">Edit Template Variables</Button>
                                </Flex> */}

                                {/* <Flex align="center" mt="20">
                                    <Text mr="10">Send to - </Text>

                                    <RadioGroup onChange={(value) => setSendTo(value)} value={sendTo}>
                                        <Stack direction='row'>
                                            <Radio mr="5" value='number'>Individual</Radio>
                                            <Radio value='plan'>Plan</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Flex> */}

                                <Flex>
                                    <div style={{'flex': '50%'}}>
                                        
                                        {/* <Flex mt="10" align="center">
                                            <Text mr="10">To {sendTo === 'number' ? 'Number' : 'Plan' }</Text>
                                            <Input onChange={(e) => setRecipient(e.target.value)} placeholder={sendTo === 'number' ? 'Enter Phone Number' : 'Enter Plan Name (eg. all, all_creators)'} width="60%" value={recipient}/>
                                        </Flex> */}
                                        {/* <Flex mt="10" justify="space-between">
                                            <Text fontSize="xl"><u>Query data</u></Text>
                                        </Flex> */}
                                        {queryData.map(data => {
                                            return(
                                                <Flex mt="10" align="center" key={data}>
                                                    <Text mr="10">{data}</Text>
                                                    <Input onChange={(e) =>{
                                                            let val = e.target.value; 
                                                            let params = queryParams;
                                                            params[`${data}`] = val
                                                            setQueryParams(params)
                                                        }} placeholder={`Enter ${data} query parameter`} width="60%" value={queryParams.data}/>
                                                </Flex>
                                            )
                                        })}
                                        <Flex mt="10" align="center">
                                            <Text mr="10">Schedule WhatsApp</Text>
                                            <Input onChange={(e) => setWappSchedule(e.target.value)} value={wappSchedule} type="datetime-local" placeholder="Schedule WhatsApp" width="40%" />
                                        </Flex>
                                        <Text mt="2" fontSize="0.8rem">
                                            <strong>NOTE - Please schedule atleast 30 minutes from current time. Once scheduled, it can not be changed</strong>
                                        </Text>
                                        {sendingWapp ? <Spinner /> : <Button onClick={submitHandler} mt="20" colorScheme="red">Send WhatsApp</Button> }
                                        <Text mt="2">{message}</Text>
                                    </div>

                                    {/* <Box style={{'flex' : '50%'}} mt='10'>
                                        <div dangerouslySetInnerHTML={{__html: templateData.template}}></div>
                                    </Box> */}
                                </Flex>
                            </>
                        }

                        
                    </>
                }
            </Flex>

            {/* <ViewTemplateModal isOpen={viewTemplate} onClose={() => setViewTemplate(false)} templateData={templateData}/>
            {templateData.template && 
                <EditVariablesModal isOpen={editVariables} onClose={() => setEditVariables(false)} templateData={templateData} onUpdate={(templateData) => setTemplateData(templateData)}/> 
            } */}
        </Box >
    )

}

export default Whatsapp;