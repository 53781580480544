import React, { useEffect, useState } from 'react';
import { useApp } from '../context';

import {
    Spinner,
    Flex,
    Text,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Button,
} from '@chakra-ui/react';
import WhatsappTemplateModal from '../components/WhatsappTemplates/WhatsappTemplateModal';
import ViewTemplateModal from '../components/WhatsappTemplates/ViewTemplateModal';

const WhatsappTemplates = () => {
    const app = useApp();

    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedMailTemplate, setSelectedMailTemplate] = useState({});
    const [reset, setReset] = useState(0);
    const [viewTemplate, setViewTemplate] = useState(false);

    const getTemplates = async () => {
        setLoading(true);

        const response = await app.helloFambase(
            'GET',
            `dashboard/list-whatsapp-templates`
        );

        console.log(response);
        setLoading(false);

        if (response.success) {
            setWhatsappTemplates(response.data);
            console.log(response.data);
        }
    };

    useEffect(() => {
        getTemplates();
    }, [reset]);

    const buttonClickHandler = index => {
        setShowModal(true);
        setSelectedMailTemplate(whatsappTemplates[index]);
    };

    const viewTemplateHandler = index => {
        setSelectedMailTemplate(whatsappTemplates[index]);
        setViewTemplate(true);
    };

    const exportTemplate = index => {
        let a = document.createElement('a');

        a.href =
            'data:text/plain;charset=utf-8,' +
            encodeURIComponent(whatsappTemplates[index].template);
        a.download = `${whatsappTemplates[index].templateName}.html`;

        a.click();
    };

    return (
        <>
            <Text fontSize="3xl" align="center" mt="5">
                Whatsapp Templates
            </Text>

            <Flex width="25%" direction="column">
                <Button
                    mt="10"
                    colorScheme="teal"
                    onClick={() => {
                        setShowModal(true);
                        setSelectedMailTemplate({});
                    }}
                >
                    Add Whatsapp Template
                </Button>
            </Flex>

            {loading ? (
                <Flex align="center" justify="center" w="100%" h="100%">
                    <Spinner />
                </Flex>
            ) : (
                <>
                    <Box
                        w="80%"
                        h="80%"
                        align="center"
                        m="auto"
                        mt="5"
                        overflow="scroll"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '0px',
                            },
                        }}
                    >
                        <Table>
                            <Tbody>
                                {whatsappTemplates.map((template, index) => {
                                    return (
                                        <Tr textAlign="center" key={index}>
                                            <Td textAlign="center" border="1px">
                                                {template.queryName}
                                            </Td>
                                            <Td
                                                width="40"
                                                textAlign="center"
                                                border="1px"
                                            >
                                                <Button
                                                    onClick={buttonClickHandler.bind(
                                                        'this',
                                                        index
                                                    )}
                                                    colorScheme="green"
                                                >
                                                    Update
                                                </Button>
                                            </Td>
                                            <Td
                                                width="40"
                                                textAlign="center"
                                                border="1px"
                                            >
                                                <Button
                                                    onClick={viewTemplateHandler.bind(
                                                        'this',
                                                        index
                                                    )}
                                                    colorScheme="yellow"
                                                >
                                                    View
                                                </Button>
                                            </Td>
                                            <Td
                                                width="40"
                                                textAlign="center"
                                                border="1px"
                                            >
                                                <Button
                                                    onClick={exportTemplate.bind(
                                                        'this',
                                                        index
                                                    )}
                                                    colorScheme="red"
                                                >
                                                    Export
                                                </Button>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                </>
            )}

            {showModal && (
                <WhatsappTemplateModal
                    isOpen={true}
                    onClose={() => {
                        setShowModal(false);
                        setReset(reset + 1);
                    }}
                    templateData={selectedMailTemplate}
                />
            )}

            <ViewTemplateModal
                isOpen={viewTemplate}
                onClose={() => {
                    setViewTemplate(false);
                }}
                templateData={selectedMailTemplate}
            />
        </>
    );
};

export default WhatsappTemplates;
