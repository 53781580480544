import React, { useEffect, useRef, useState } from 'react';

import Papa from 'papaparse';
import papaparse from 'papaparse';

import {
    Center,
    Flex,
    Heading,
    Input,
    Select,
    Text,
    Spinner,
    Box,
    Button,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerHeader,
    DrawerCloseButton,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import { useApp } from '../context';

const CertificateGenerator = () => {
    const app = useApp();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(true);
    const [certificateGenerationValid, setCertificateGenerationValid] =
        useState(false);
    const [generatorLoading, setGeneratorLoading] = useState(false);
    const [overallGeneratorLoading, setOverallGeneratorLoading] =
        useState(false);
    const [success, setSuccess] = useState(false);

    const [plans, setPlans] = useState({});
    const [workshops, setWorkshops] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedWorkshop, setSelectedWorkshop] = useState('');

    const [email, setEmail] = useState('');

    const [count, setCount] = useState(0);

    const [selectedAttendance, setSelectedAttendance] = useState([]);
    const [loadingAttendance, setLoadingAttendance] = useState(false);

    const fileRef = useRef();

    useEffect(() => {
        getPlans();

        setPlans({});
        setWorkshops([]);
        setSelectedPlan('');
    }, []);

    const getPlans = async () => {
        try {
            setLoading(true);

            const result = await app.helloFambase(
                'GET',
                'dashboard/certificate-plans'
            );

            setLoading(false);

            if (result.success) {
                console.log(result.data);
                setPlans(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const planSelecthandler = async e => {
        setOverallGeneratorLoading(true);
        console.log(e.target.value);
        setSelectedPlan(e.target.value);
        setWorkshops(plans[e.target.value]);

        try {
            const result = await app.helloFambase(
                'GET',
                'dashboard/overall-certificate-check/' + e.target.value
            );

            setCertificateGenerationValid(result.data.overallCertificateValid);
        } catch (error) {
            console.log(error);
        } finally {
            setOverallGeneratorLoading(false);
        }
    };

    const getAttendance = async workshopId => {
        setLoadingAttendance(true);
        try {
            const result = await app.helloFambase(
                'POST',
                'dashboard/get-attendance',
                {
                    workshop: workshopId,
                    plan: selectedPlan,
                }
            );

            if (result?.success) {
                console.log(result.data);
                setSelectedAttendance(result.data);
                setLoadingAttendance(false);
            } else {
                setSelectedAttendance([]);
                setLoadingAttendance(false);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingAttendance(false);
        }
    };

    const workshopSelecthandler = async e => {
        setSelectedWorkshop(e.target.value);
        await getAttendance(e.target.value);
    };

    const submitHandler = async () => {
        console.log('submitting');
        try {
            if (selectedWorkshop) {
                setGeneratorLoading(true);

                const file = fileRef.current.files[0];

                if (email) {
                    const res = await app.helloFambase(
                        'POST',
                        'dashboard/mark-attendance',
                        {
                            data: [['', '', email]],
                            workshop: selectedWorkshop,
                            plan: selectedPlan,
                            planName: plans[selectedPlan][0].planName,
                            workshopName: plans[selectedPlan].filter(
                                p => p.workshopId == selectedWorkshop
                            )[0].workshopName,
                        }
                    );

                    setGeneratorLoading(false);

                    if (res.success) {
                        setCount(res.data.count);
                    }
                } else if (file) {
                    Papa.parse(file, {
                        complete: async data => {
                            if (
                                data &&
                                data.data &&
                                Array.isArray(data.data) &&
                                data.data.length > 1
                            ) {
                                let row_index = 0;
                                let currentRow = data.data[row_index];

                                while (currentRow.length < 3) {
                                    row_index++;
                                    currentRow = data.data[row_index];
                                }

                                if (
                                    !currentRow[0].toLowerCase() ===
                                    'first name'
                                ) {
                                    alert('Incorrect column heading');
                                    return;
                                }
                                if (
                                    !currentRow[0][1].toLowerCase() ===
                                    'last name'
                                ) {
                                    alert('Incorrect column heading');
                                    return;
                                }

                                if (
                                    !currentRow[0][2].toLowerCase() === 'email'
                                ) {
                                    alert('Incorrect column heading');
                                    return;
                                }
                                console.log('marking attendance');
                                const res = await app.helloFambase(
                                    'POST',
                                    'dashboard/mark-attendance',
                                    {
                                        data: data.data.slice(row_index + 1),
                                        workshop: selectedWorkshop,
                                        plan: selectedPlan,
                                        planName:
                                            plans[selectedPlan][0].planName,
                                        workshopName: plans[
                                            selectedPlan
                                        ].filter(
                                            p =>
                                                p.workshopId == selectedWorkshop
                                        )[0].workshopName,
                                    }
                                );

                                setGeneratorLoading(false);

                                if (res.success) {
                                    setCount(res.data.count);
                                }
                            }
                        },
                    });
                }
            } else alert('Please fill all the fields');
        } catch (error) {
            console.log(error);
        } finally {
            setOverallGeneratorLoading(true);
            try {
                const result = await app.helloFambase(
                    'GET',
                    'dashboard/overall-certificate-check/' + selectedPlan
                );

                setCertificateGenerationValid(
                    result.data.overallCertificateValid
                );

                await getAttendance(selectedWorkshop);
            } catch (error) {
                console.log(error);
            } finally {
                setOverallGeneratorLoading(false);
            }

            fileRef.current.value = '';
        }
    };

    const downloadSampleFileHandler = () => {
        const csv = papaparse.unparse({
            fields: ['First Name', 'Last Name', 'Email'],
            data: [],
        });

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);

        a.download = 'sample-generate-certificate.csv';
        document.body.appendChild(a);
        a.click();
    };

    const generateOverallCertificateHandler = async () => {
        if (!selectedPlan) {
            alert('Please select a plan');
            return;
        }

        setOverallGeneratorLoading(true);

        const res = await app.helloFambase(
            'POST',
            'dashboard/certificate-generator',
            {
                plan: selectedPlan,
                workshopName: '',
                planName: plans[selectedPlan][0].planName,
            }
        );

        setOverallGeneratorLoading(false);

        if (res.success) {
            setSuccess(true);
            setCount(res.data.count);
        }
    };

    if (loading) {
        return (
            <Flex height={'100%'} align={'center'} justify={'center'}>
                <Center>
                    <Spinner size="xl" />
                </Center>
            </Flex>
        );
    }

    return (
        <>
            <Center>
                <Heading>Attendance & Certificate Generator</Heading>
            </Center>

            <Flex mt="10" align={'flex-start'} direction="column">
                <Text mr="10">Select Plan</Text>
                <Select
                    value={selectedPlan}
                    width="30%"
                    onChange={planSelecthandler}
                    mt={3}
                >
                    <option>Select Plan</option>
                    {plans &&
                        Object.keys(plans).length &&
                        Object.keys(plans).map((plan, index) => {
                            return (
                                <option key={index} value={plan}>
                                    {plans[plan][0].planName}
                                </option>
                            );
                        })}
                </Select>
            </Flex>

            <Flex mt="10" align={'flex-start'} direction="column">
                <Text mr="10">Select Workshop</Text>
                <Select
                    value={selectedWorkshop}
                    width="30%"
                    onChange={workshopSelecthandler}
                >
                    <option>Select Workshop</option>
                    {workshops &&
                        workshops.length &&
                        workshops.map((workshop, index) => {
                            return (
                                <option key={index} value={workshop.workshopId}>
                                    {workshop.workshopName}
                                </option>
                            );
                        })}
                </Select>
                {loadingAttendance ? (
                    <Button
                        width="80"
                        onClick={() => {}}
                        mt="1"
                        colorScheme="green"
                        isLoading={loadingAttendance}
                        loadingText="Loading Attendance"
                    >
                        Attendance
                    </Button>
                ) : selectedAttendance.length ? (
                    <Button
                        width="80"
                        onClick={onOpen}
                        mt="1"
                        colorScheme="green"
                    >
                        View attendance
                    </Button>
                ) : null}
            </Flex>

            <Flex mt="10" align={'flex-start'} direction="column">
                <Text mr="10">Enter Email</Text>
                <Input
                    placeholder="Enter Email or Upload File"
                    width="30%"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </Flex>

            <Flex mt="10" align={'flex-start'} direction="column">
                <Text mr="10">Upload File</Text>
                <Input accept=".csv" width="30%" type="file" ref={fileRef} />
            </Flex>

            <Flex direction={'column'} mt="5">
                <Button
                    width="80"
                    mt="5"
                    colorScheme={'blue'}
                    onClick={downloadSampleFileHandler}
                >
                    Download Sample File
                </Button>
                <Flex align={'flex-start'} direction="column">
                    {generatorLoading ? (
                        <Spinner mt="10" />
                    ) : (
                        <Button
                            width="80"
                            onClick={submitHandler}
                            mt="10"
                            colorScheme="green"
                        >
                            Upload Attendance
                        </Button>
                    )}
                    {overallGeneratorLoading ? (
                        <Spinner ml="20" mt="20" />
                    ) : (
                        <Button
                            onClick={generateOverallCertificateHandler}
                            mt="3"
                            width="80"
                            colorScheme={'red'}
                            disabled={!certificateGenerationValid}
                        >
                            Generate Overall Certificate
                        </Button>
                    )}
                </Flex>
                {success ? (
                    <Text textColor={'green'} mt="10">
                        Certificates Generated Successfully - {count}
                    </Text>
                ) : null}
            </Flex>
            <Drawer
                placement={'right'}
                onClose={onClose}
                isOpen={isOpen}
                size="xl"
            >
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody>
                        <DrawerHeader>
                            Workshop Attendees Count -{' '}
                            <span style={{ color: 'green' }}>
                                {selectedAttendance.length}
                            </span>
                        </DrawerHeader>

                        <Flex>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Email</Th>
                                        <Th>Name</Th>
                                        <Th>Registered</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {loadingAttendance && <Spinner mt="10" />}
                                    {selectedAttendance.map((item, index) => (
                                        <Tr key={`attendance_table_${index}`}>
                                            <Td>{item.email}</Td>
                                            <Td>{item.name}</Td>
                                            <Td>{item.status}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default CertificateGenerator;
