import React, { useState } from 'react';

import {
    Text,
    Flex,
    Input,
    Button,
    Table,
    Tbody,
    Tr,
    Td,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody
} from '@chakra-ui/react';

import { useApp } from '../../context';
import ViewMembersModal from './ViewMembersModal';

const MailingListDataModal = ({mailingListData, closeModal}) => {
    const [email, setEmail] = useState("");
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailMessage, setEmailMessage] = useState(null);
    const [membersOpenModal, setMembersOpenModal] = useState(false);

    const app = useApp();

    const emailSearchHandler = async () => {
        setEmailLoading(true);
        setEmailMessage(null);

        const data = {
            email: email,
            mailingList: mailingListData.address
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/search-mailing-list/email',
            data
        )
        console.log(response);
        setEmailLoading(false);

        if(response.success && response.data){
            if(response.data.success) setEmailMessage('Email is Present');
            else setEmailMessage('Email is NOT Present');
        }
    }

    return(
        <>
            <Modal size="5xl" isOpen={mailingListData} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Mailing List - {mailingListData.address}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mt="10" mb="8px">Enter email address to search: </Text>
                        <Flex>
                            <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email address" size="md" />
                            <Button onClick={emailSearchHandler} ml="5" colorScheme="red">
                                { emailLoading ? <Spinner /> : 'Submit'}
                            </Button>
                        </Flex>
                        { emailMessage && <Text size="md" mb="8px" mt="5">{emailMessage}</Text>}

                        <Table mt="5" variant="simple">
                            <Tbody>
                                <Tr>
                                    <Td>Access Level</Td>
                                    <Td>{mailingListData.access_level}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Address</Td>
                                    <Td>{mailingListData.address}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Created At</Td>
                                    <Td>{mailingListData.created_at}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Description</Td>
                                    <Td>{mailingListData.description}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Members Count</Td>
                                    <Td>
                                        {mailingListData.members_count} &emsp; 
                                        <Button 
                                            colorScheme="teal" 
                                            onClick={() => setMembersOpenModal(true)}
                                        >
                                            View Members
                                        </Button>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Name</Td>
                                    <Td>{mailingListData.name}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Reply Preference</Td>
                                    <Td>{mailingListData.reply_preference}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ViewMembersModal open={membersOpenModal} close={() => setMembersOpenModal(false)} mailingListData={mailingListData} />
        </>
    )
}

export default MailingListDataModal;