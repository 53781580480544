import React, { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  Input,
  Badge,
  Tooltip
} from '@chakra-ui/react';

import { useApp } from '../../context';

const SuspendUser = ({ userId, status, ChangeUserSuspendStatus }) => {
  const app = useApp();
  const [suspendNotes, setSuspendNotes] = useState("");
  const [unsuspendNotes, setunSuspendNotes] = useState("");

  // {
  //     "success": true,
  //     "apiVersion": "V1",
  //     "data": {
  //         "userDetails": {
  //             "name": "magicalbox52",
  //             "email": "magicalbox52@gmail.com"
  //         },
  //         "data": "user suspended"
  //     }
  // }
  const Suspend = async (event) => {
    try {
      event.preventDefault();
      let body = {
        notes: suspendNotes,
        id: userId
      }

      let res = await app.helloFambase('POST', `dashboard/suspend`, body);

      console.log("USER SUSPEND ", res);
      if (res.data.data === 'user suspended') {
        ChangeUserSuspendStatus(2);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const unSuspend = async (event) => {
    try {
      event.preventDefault();
      let body = {
        notes: unsuspendNotes,
        id: userId
      }

      let res = await app.helloFambase('POST', `dashboard/unsuspend`, body);

      console.log("USER UNSUSPEND ", res);
      if (res.data.data === 'user unsuspended') {
        ChangeUserSuspendStatus(1);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box mt="4">
      {status === 1 ? (
        <form onSubmit={Suspend}>
          <FormControl id="suspendNotes" isRequired>
            <Input placeholder="Suspending Notes" value={suspendNotes} onChange={({ target }) => setSuspendNotes(target.value)} />
          </FormControl>
          <Tooltip placement="top" fontSize="md" hasArrow label="WARNING: The user won't be able to login. The creators page and posts won't be accessible anymore." bg="red.600">
            <Button
              size="sm"
              w={'100%'}
              variant="outline"
              colorScheme="red"
              type="submit"
              mt="3"
            >
              Suspend User
            </Button>
          </Tooltip>

        </form>
      ) : (
        <Box mt="4">
          <Badge colorScheme="red">User has been Suspended</Badge>
          <Box mt="4">
            <form onSubmit={unSuspend}>
              <FormControl id="unsuspendNotes" isRequired>
                <Input placeholder="Unsuspending Notes" value={unsuspendNotes} onChange={({ target }) => setunSuspendNotes(target.value)} />
              </FormControl>
              <Button
                size="sm"
                w={'100%'}
                variant="outline"
                colorScheme="green"
                type="submit"
                mt="3"
              >
                Unuspend User
            </Button>
            </form>
          </Box>
        </Box>
      )
      }
    </Box >
  );
};

export default SuspendUser;
