import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import context from './context';
import useAuth from '../auth/useAuth';
import { api_url } from '../../config';

const Provider = ({ children }) => {
  const auth = useAuth();

  // Function for API calls. 
  const helloFambase = async (method = 'GET', url = '', data = {}) => {
    try {
      let response = null;

      const config = {};
      config.method = method;
      config.url = api_url + url;
      config.headers = {};

      config.headers.auth = auth.userToken;

      console.log(config);
      if (method !== 'GET' && method !== 'DELETE') {
        config.data = data;
      }

      response = await axios(config);

      return responseController(response);
    } catch (err) {
      console.log(err);
    }
  };

  // Response from the helloFambase is filtered in this function.
  const responseController = response => {
    try {
      if (response.data.success) {
        return response.data;
      }
      console.log(response);
      if (
        response.data.success === false &&
        response.data.error &&
        (response.data.error.status === 4060 ||
          response.data.error.status === 4059)
      ) {
        // token expire logout or invalid token
        auth.signOut();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <context.Provider
      value={{
        helloFambase,
      }}
    >
      {children}
    </context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
