import React, { useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box,
} from '@chakra-ui/react';

const ViewTemplateModal = ({ isOpen, onClose, templateData }) => {
    function convertToWords(inputString) {
        if (!inputString) return '';
        return inputString.replace(/([A-Z])/g, ' $1').toLowerCase();
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {convertToWords(templateData.queryName)}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Box
                        h="100%"
                        dangerouslySetInnerHTML={{
                            __html: templateData.componentBodyNew,
                        }}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ViewTemplateModal;
