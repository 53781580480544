import React, { useState, useEffect } from 'react';

import {
    Center, Box, Select, Flex,
    Heading, Text, Button, Spinner,
    RadioGroup, Radio, Stack,
    Input

} from '@chakra-ui/react';

import { useApp } from '../context';
import ViewTemplateModal from '../components/MailTemplates/ViewTemplateModal';
import EditVariablesModal from '../components/Email/EditVariablesModal';

const Email = () => {
    const app = useApp();
    const [currentType, setCurrentType] = useState('');
    const [currentTemplate, setCurrentTemplate] = useState('');
    const [options, setOptions] = useState([]);
    const [templateId, setTemplateId] = useState();
    const [templateData, setTemplateData] = useState({});
    const [viewTemplate, setViewTemplate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editVariables, setEditVariables] = useState(false);
    const [sendTo, setSendTo] = useState('');
    const [recepient, setRecipient] = useState('');
    const [mailSchedule, setMailSchedule] = useState(null);
    const [sendingMail, setSendingMail] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = async (type, index) => {
        setCurrentType(type)
        if (index != 0) {
            setTemplateId(options[index - 1].id)
            getTemplateData(options[index - 1].id);
        }
        else {
            setCurrentTemplate('')
        }
    }

    const getTemplateData = async (id) => {
        try{
            setLoading(true);

            const result = await app.helloFambase(
                'GET',
                `dashboard/get-template-data/${id}`
            )

            setLoading(false);

            if(result.success){
                setTemplateData(result.data);
                setCurrentTemplate(result.data.template);

                setRecipient('');
                setMailSchedule('');
                setMessage(null);
                setSendTo('')
            }
        } catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            try{
                let response = await app.helloFambase(
                    'GET',
                    'dashboard/broadcastEmailOptions'
                );

                if (response.success) {
                    console.log(response);
                    setOptions(response.data)
                }
            }catch(e){
                console.log(e);
            }
        }

        getOptions();
        
    }, [])

    const submitHandler = async () => {
        try{
            setSendingMail(true);
            setMessage('');
            
            if(!recepient){
                setSendingMail(false);
                alert('Please fill recepient.');
                return;
            }

            let obj = {
                templateData: templateData,
                recepient: recepient,
                list: sendTo === 'list' ? true : false,
                mailSchedule: mailSchedule
            }

            // console.log(obj.templateData);
            const result = await app.helloFambase(
                'POST',
                'dashboard/send-mail',
                obj
            )

            setSendingMail(false);

            if(result.success){
                setMessage('Mail sent successfully.');
            }

        } catch(err){
            console.log(err);
            setSendingMail(false);

            setMessage('Something went wrong. Please try again later.')
        }
    }

    return (
        <Box
            h="100%"
            overflow="auto"
            pr="10"
        >
            <Flex direction="column" h="100%">
                <Heading mx="auto" size="lg">
                    Send Email
                </Heading>
                <Center>
                    <Select mt="4" placeholder="Select option" width="40%" onChange={e => handleChange(e.target.value, e.target.options.selectedIndex)} size="lg">
                        {
                            options.map((option, index) => {
                                return (
                                    <option key={index} value={option.templateName}>{option.templateName}</option>
                                )
                            })
                        }
                    </Select>
                </Center>
                {loading ? 
                    <Center>
                        <Spinner mt="5"></Spinner>
                    </Center> 
                :
                    <>
                        {templateData.template &&
                            <>
                                <Flex mt="10" justify="space-between">
                                    <Text fontSize="2xl">Template - {currentType}</Text>
                                    {/* <Button onClick={() => setViewTemplate(true)} colorScheme="yellow">View Template</Button> */}
                                </Flex>

                                <Flex>
                                    <Button width="60" onClick={() => setEditVariables(true)} mt="10" colorScheme="red">Edit Template Variables</Button>
                                </Flex>

                                <Flex align="center" mt="20">
                                    <Text mr="10">Send to - </Text>

                                    <RadioGroup onChange={(value) => setSendTo(value)} value={sendTo}>
                                        <Stack direction='row'>
                                            <Radio mr="5" value='email'>Email</Radio>
                                            <Radio value='list'>Mailing List</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Flex>

                                <Flex>
                                    {sendTo &&
                                        <div style={{'flex': '50%'}}>
                                            <Flex mt="10" align="center">
                                                <Text mr="10">From Name</Text>
                                                <Input onChange={(e) => { setTemplateData({...templateData, fromName: e.target.value})}} placeholder="From Name" width="40%" value={templateData.fromName}/>
                                            </Flex>

                                            <Flex mt="10" align="center">
                                                <Text mr="10">From Email</Text>
                                                <Input onChange={(e) => { setTemplateData({...templateData, fromMail: e.target.value})}} placeholder="From Email" width="40%" value={templateData.fromMail.replace(/[<>]/g, '')}/>
                                            </Flex>

                                            <Flex mt="10" align="center">
                                                <Text mr="10">Subject</Text>
                                                <Input onChange={(e) => { setTemplateData({...templateData, subject: e.target.value})}}  placeholder="Subject" width="60%" value={templateData.subject}/>
                                            </Flex>
                                        
                                            <Flex mt="10" align="center">
                                                <Text mr="10">To {sendTo === 'email' ? 'Email' : 'Mailing List' }</Text>
                                                <Input onChange={(e) => setRecipient(e.target.value)} placeholder={sendTo === 'email' ? '(Emails separated by comma for multiple recepients)' : 'Enter Mailing List Name (eg. all, all_creators)'} width="60%" value={recepient}/>
                                            </Flex>

                                            <Flex mt="10" align="center">
                                                <Text mr="10">Cc</Text>
                                                <Input onChange={(e) => { setTemplateData({...templateData, cc: e.target.value})}}  placeholder="Cc" width="60%" value={templateData.cc}/>
                                            </Flex>

                                            <Flex mt="10" align="center">
                                                <Text mr="10">Bcc</Text>
                                                <Input onChange={(e) => { setTemplateData({...templateData, bcc: e.target.value})}}  placeholder="Bcc" width="60%" value={templateData.bcc}/>
                                            </Flex>

                                            <Flex mt="10" align="center">
                                                <Text mr="10">Schedule Mail</Text>
                                                <Input onChange={(e) => setMailSchedule(e.target.value)} value={mailSchedule} type="datetime-local" placeholder="Schedule Mail" width="40%" />
                                            </Flex>
                                            <Text mt="2" fontSize="0.8rem"><strong>NOTE - Once scheduled, it can not be changed</strong></Text>

                                            {sendingMail ? <Spinner /> : <Button onClick={submitHandler} mt="20" colorScheme="red">Send Email</Button> }
                                            <Text mt="2">{message}</Text>
                                        </div>
                                    }

                                    <Box style={{'flex' : '50%'}} mt={sendTo ? '-60' : '20'}>
                                        <div dangerouslySetInnerHTML={{__html: templateData.template}}></div>
                                    </Box>
                                </Flex>
                            </>
                        }

                        
                    </>
                }
            </Flex>

            <ViewTemplateModal isOpen={viewTemplate} onClose={() => setViewTemplate(false)} templateData={templateData}/>
            {templateData.template && <EditVariablesModal isOpen={editVariables} onClose={() => setEditVariables(false)} templateData={templateData} onUpdate={(templateData) => setTemplateData(templateData)}/> }
        </Box >
    )

}

export default Email;