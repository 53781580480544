import React, { useState, useEffect } from 'react';

import {
    Center, Box, Select, Flex,
    Heading, Text, Button, Spinner,
    RadioGroup, Radio, Stack,
    Input, InputRightAddon, InputGroup

} from '@chakra-ui/react';

import { useApp } from '../context';
import { environment } from '../config';

const SendTemplateMail = () => {
    const app = useApp();

    const [loading, setLoading] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [mailingList, setMailingList] = useState('');
    const [from, setFrom] = useState('TTT Academy');
    const [fromMail, setFromMail] = useState('no-reply');
    const [subject, setSubject] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submitHandler = async () => {
        try{
            setLoading(true);
            setError(false);
            setSuccess(false);

            const result = await app.helloFambase(
                'POST',
                'dashboard/send-template-mail',
                {
                    templateName,
                    mailingList,
                    from,
                    fromMail,
                    subject
                }
            )

            setLoading(false);

            if(result.success){
                console.log(result.data);
                setSuccess(true);

                // setTemplateName('');
                // setMailingList('');
                // setSubject('');
                // setFrom('Neha from TTT Academy');
                // setFromMail('neha');
            }

            else {
                setError(true);
            }

        } catch(err){
            setError(true);
            console.log(err);
        }
    }

    return (
        <Box
            h="100%"
            overflow="auto"
            pr="10"
        >
            <Flex direction="column" h="100%">
                <Heading mx="auto" size="lg">
                    Send Template Email
                </Heading>

                <Flex mt="20" align={'center'}>
                    <Text fontSize={'xl'}>Enter Template name</Text>
                    <Input value={templateName} onChange={(e) => setTemplateName(e.target.value)} ml="10" width="50%" type="text" placeholder="Template name" />
                </Flex>

                <Flex mt="10" align={'center'}>
                    <Text fontSize={'xl'}>Enter Mailing List</Text>
                    <InputGroup flex={1}>
                        <Input value={mailingList} onChange={(e) => setMailingList(e.target.value)} ml="10" width="50%" type="text" placeholder="Mailing List" />
                        <InputRightAddon>_mailing_list@{environment === 'production' ? 'mail.terriblytinytales.com' : 'mg1.tdingapp.com'}</InputRightAddon>
                    </InputGroup>
                </Flex>

                <Flex mt="10" align={'center'}>
                    <Text fontSize={'xl'}>Subject</Text>
                    <Input value={subject} onChange={(e) => setSubject(e.target.value)} ml="10" width="50%" type="text" placeholder="Subject" />
                </Flex>

                <Flex mt="10" align={'center'}>
                    <Text fontSize={'xl'}>From</Text>
                    <Input value={from} onChange={(e) => setFrom(e.target.value)} ml="10" width="50%" type="text" placeholder="From" />
                </Flex>

                <Flex mt="10" align={'center'}>
                    <Text fontSize={'xl'}>From Mail</Text>
                    <InputGroup flex={1}>
                        <Input value={fromMail} onChange={(e) => setFromMail(e.target.value)} ml="10" width="50%" type="text" placeholder="From Mail" />
                        <InputRightAddon>@{environment === 'production' ? 'mail.terriblytinytales.com' : 'corefam.com'}</InputRightAddon>
                    </InputGroup>
                </Flex>

                <Button onClick={submitHandler} mt="20" width="10%" colorScheme={'red'}>{loading ? <Spinner /> : 'Submit'}</Button>

                {success && <Text mt="10" fontSize={'xl'}>Success</Text>}
                {error && <Text mt="10" fontSize={'xl'}>Error</Text>}
            </Flex>
        </Box>
    )

}

export default SendTemplateMail;