import React, { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    HStack,
    Input,
    Text,
    Flex,
    Select,
    Button,
    Heading,
    Spinner,
} from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import { useApp } from '../../context';
import 'react-quill/dist/quill.snow.css';

const JobForm = ({ selectedCompany, navigateBack, selectedJob }) => {
    const app = useApp();
    const [minimumDate, setMinimumDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        groupId: 0,
        title: '',
        description: '',
        package: '',
        experience: '',
        jobType: '',
        location: '',
        assignmentPresent: false,
        deadline: '',
        active: true,
    });
    const [warn, setWarn] = useState({
        quillwarn: false,
    });

    useEffect(() => {
        if (selectedJob) {
            setFormData({
                groupId: selectedJob.groupId,
                title: selectedJob.jobTitle,
                description: selectedJob.jobDescription,
                package: selectedJob.package,
                experience: selectedJob.experience,
                jobType: selectedJob.jobType,
                location: selectedJob.location,
                assignmentPresent: selectedJob.assignmentPresent,
                deadline: selectedJob.deadline,
                active: selectedJob.active,
            });
        } else {
            setFormData(prevData => ({
                ...prevData,
                title: '',
            }));
        }
    }, [selectedJob]);

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 2);
        const tomorrowFormatted = tomorrow.toISOString().split('T')[0];
        setMinimumDate(tomorrowFormatted);
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGroupChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            groupId: parseInt(value),
        }));
    };

    const handleActiveChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            active: value === 'true',
        }));
    };

    const handleAssignmentChange = (value) => {
        setFormData(prevData => ({
            ...prevData,
            assignmentPresent: value === 'true',
        }));
    };
    
    const handleDescriptionChange = (value) => {
        const modifiedValue = value.replace(
          /<a\b[^>]*href=["'](https?:\/\/)?(www\.[^"'\s]+\.[^\s]+|[^"'\s]+\.[^\s]+)["'][^>]*>/gi,
          (match, p1, p2) => {
            const url = p1 ? match.replace(p1, 'https://') : `<a href="https://${p2}">`;
            return url;
          }
        );
        setFormData((prevData) => ({
          ...prevData,
          description: modifiedValue,
        }));
      };
    
    const dataToSend = {
        ...formData,
        companyId: selectedCompany.companyId,
        ...(selectedJob && { jobId: selectedJob.jobId }),
    };
    const handleSubmit = async (event) => {
        if(loading) return;
        event.preventDefault();
        if (dataToSend.deadline === '') {
            dataToSend.deadline = null;
        }
        setLoading(true);
        try {
            const response = await app.helloFambase(
                'POST',
                'dashboard/upsert-job',
                dataToSend
            );
            if (response.success) {
                window.alert(
                    'Job added/updated successfully!'
                );
                window.location.reload();
            } else {
                window.alert('Failed to add/update job:', response.error);
                setLoading(false);
            }
        } catch (error) {
            alert('Failed to add/update job:', error);
        }
    };

    return (
        <Box m="3rem 3rem">
            <Heading size="md" textAlign="center" mb="1rem">
                <span style={{ color: 'red' }}>*</span> Required
            </Heading>
            <form onSubmit={handleSubmit}>
                <FormControl mb="1rem">
                    <FormLabel>Company Name</FormLabel>
                    <Input
                        type="text"
                        value={
                            selectedCompany ? selectedCompany.companyName : ''
                        }
                        isReadOnly
                    />
                </FormControl>
                <FormControl mb="1rem">
                    <FormLabel>
                        Plan <span style={{ color: 'red' }}>*</span>
                    </FormLabel>

                    <RadioGroup
                        name="groupId"
                        onChange={value => handleGroupChange(value)}
                        value={formData.groupId.toString()}
                    >
                        <HStack spacing="1rem">
                            <Radio value="1" required>
                                Writing That Sells
                            </Radio>
                            <Radio value="2">Writing That Sells Pro</Radio>
                            <Radio value="3">Copywriting That Sells</Radio>
                            <Radio value="4">Design That Sells</Radio>
                            <Radio value="999">All</Radio>
                        </HStack>
                    </RadioGroup>
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>
                        Job Title <span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <Input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        placeholder="Add a Title"
                        required={!selectedJob}
                    />
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>
                        Description{' '}
                        {/* <span style={{ color: 'red' }}>
                            * Needs to be atleast 200 characters
                        </span> */}
                    </FormLabel>
                    {warn.quillwarn && (
                        <Text color="red">Please add a description</Text>
                    )}
                    <div style={{ minHeight: '30rem' }}>
                        <ReactQuill
                            value={formData.description}
                            onChange={handleDescriptionChange}
                            style={{ height: '30rem' }}
                        />
                    </div>
                </FormControl>

                <FormControl mb="1rem" mt="4rem">
                    <FormLabel>Package</FormLabel>
                    <Input
                        type="text"
                        name="package"
                        value={formData.package}
                        onChange={handleChange}
                        placeholder="Eg. 10-12 LPA"
                    />
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>Experience</FormLabel>
                    <Input
                        type="text"
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                        placeholder="0-1 Year"
                    />
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>Job Type</FormLabel>
                    <Select
                        name="jobType"
                        value={formData.jobType}
                        onChange={handleChange}
                    >
                        <option value="">Select</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Internship">Internship</option>
                        <option value="Remote">Remote</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Freelance">Freelance</option>
                    </Select>
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>Location</FormLabel>
                    <Input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Eg. Mumbai"
                    />
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>
                        Assignment Present{' '}
                        <span style={{ color: 'red' }}>
                            * (Default is False)
                        </span>
                    </FormLabel>
                    <RadioGroup
                        name="assignmentPresent"
                        onChange={value => handleAssignmentChange(value)}
                        value={formData.assignmentPresent.toString()}
                    >
                        <HStack spacing="1rem">
                            <Radio value="true" required>
                                True
                            </Radio>
                            <Radio value="false">False</Radio>
                        </HStack>
                    </RadioGroup>
                </FormControl>

                <FormControl mb="1rem">
                    <FormLabel>
                        Deadline{' '}
                        <span style={{ color: 'blue' }}>
                            (Hint: Deadline is optional, Minimum time should be
                            at least 48 hours)
                        </span>
                    </FormLabel>
                    <Input
                        type="date"
                        name="deadline"
                        value={
                            formData.deadline
                                ? formData.deadline.split('T')[0]
                                : ''
                        }
                        onChange={handleChange}
                        w="15rem"
                        min={minimumDate}
                    />
                </FormControl>
                {selectedJob && (
                    <FormControl mb="2rem">
                        <FormLabel>
                            Active{' '}
                            <span style={{ color: 'red' }}>
                                * (Default is True)
                            </span>
                        </FormLabel>
                        <RadioGroup
                            name="active"
                            onChange={value => handleActiveChange(value)}
                            value={formData.active.toString()}
                        >
                            <HStack spacing="1rem">
                                <Radio value="true">True</Radio>
                                <Radio value="false">False</Radio>
                            </HStack>
                        </RadioGroup>
                    </FormControl>
                )}
                <Flex justifyContent="center" gridGap="2rem" mt="2rem">
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <Button colorScheme="red" onClick={navigateBack}>
                                Back/Cancel
                            </Button>

                            <Button colorScheme="green" type="submit">
                                Submit
                            </Button>
                        </>
                    )}
                </Flex>
            </form>
        </Box>
    );
};

export default JobForm;
