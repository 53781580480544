import React, { useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box,
    Text,
    Input,
    Button,
    Spinner,
    Flex,
    Textarea,
} from '@chakra-ui/react';

import { useApp } from '../../context';

import ViewTemplateModal from './ViewTemplateModal';

const WhatsappTemplateModal = ({ isOpen, onClose, templateData }) => {
    function convertToWords(inputString) {
        if (!inputString) return '';
        return inputString.replace(/([A-Z])/g, ' $1').toLowerCase();
    }
    const [templateName, setTemplateName] = useState(
        convertToWords(templateData.queryName) || ''
    );
    const [template, setTemplate] = useState(
        templateData.componentBodyNew || ''
    );
    const [headers, setHeaders] = useState(templateData.headers || '');
    const [params, setParams] = useState(templateData.params || '');
    const [query, setQuery] = useState(templateData.whatsapp_query || '');

    const [loading, setLoading] = useState(false);
    const [showSuccess, setshowSuccess] = useState(false);
    const [showError, setshowError] = useState(false);
    const [viewTemplate, setViewTemplate] = useState(false);

    const app = useApp();

    const submitHandler = async () => {
        setshowError(false);
        setshowSuccess(false);

        if (validate()) {
            setLoading(true);

            const data = {
                componentBodyNew: template,
                templateName,
                whatsapp_query: query,
                headers,
                params,
            };

            const response = await app.helloFambase(
                'POST',
                `dashboard/update-whatsapp-template`,
                data
            );
            console.log(response);
            setLoading(false);

            if (response.success) {
                setshowSuccess(true);
                setshowError(false);
            } else {
                setshowError(true);
                setshowSuccess(false);
            }
        } else return;
    };

    const validate = () => {
        if (!templateName) {
            alert('Fill Template Name');
            return false;
        }

        if (!template) {
            alert('Fill Template');
            return false;
        }

        return true;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add/Edit Template</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Box mt="2" ml="10" mr="10" height="100%">
                        <Text mt="2" size="md" mb="8px">
                            Enter Template Name *
                        </Text>
                        <Input
                            placeholder="Enter Template Name"
                            value={templateName}
                            isRequired
                            onChange={e => setTemplateName(e.target.value)}
                        />

                        <Text mt="7" size="md" mb="8px">
                            Enter Whatsapp Template *
                        </Text>
                        <Textarea
                            cols={5}
                            isRequired
                            value={template}
                            onChange={e => setTemplate(e.target.value)}
                            placeholder="Enter Whatsapp Template"
                        />

                        <Text mt="7" size="md" mb="8px">
                            Enter Query
                        </Text>
                        <Textarea
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            placeholder="Enter Query"
                        />

                        <Text mt="7" size="md" mb="8px">
                            Enter Headers *
                        </Text>
                        <Input
                            placeholder="Enter Headers"
                            value={headers}
                            onChange={e => setHeaders(e.target.value)}
                        />

                        <Text mt="7" size="md" mb="8px">
                            Enter Params *
                        </Text>
                        <Input
                            placeholder="Enter Params"
                            value={params}
                            onChange={e => setParams(e.target.value)}
                        />

                        <Flex mt="7" justify="space-between">
                            {showSuccess ? (
                                <Text
                                    size="md"
                                    mb="8px"
                                    mt="10"
                                    color="lightgreen"
                                >
                                    Added/Updated Successfully
                                </Text>
                            ) : showError ? (
                                <Text size="md" mb="8px" mt="10" color="red">
                                    Error
                                </Text>
                            ) : (
                                <Button
                                    mb="10"
                                    onClick={submitHandler}
                                    mt="10"
                                    colorScheme="red"
                                    variant="solid"
                                    size="md"
                                >
                                    {loading ? <Spinner /> : 'Submit'}
                                </Button>
                            )}
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
            <ViewTemplateModal
                isOpen={viewTemplate}
                onClose={() => setViewTemplate(false)}
                templateData={templateData}
            />
        </Modal>
    );
};

export default WhatsappTemplateModal;
