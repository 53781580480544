let current_env = '';
let host = window.location.hostname;

if (
    process.env.NODE_ENV !== 'production' ||
    (process.env.NODE_ENV === 'production' &&
        host.includes('tttunidevdashboard'))
) {
    current_env = 'staging';
} else {
    current_env = 'production';
}

console.log('Host - ', host);
console.log('Current environment - ', current_env);

export const environment = current_env;

// export const api_url = 'http://localhost:8095/';
export const api_url =
    current_env === 'production'
        ? 'https://apilearn.terriblytinytales.com/'
        : 'https://devapilearn.terriblytinytales.com/';
// : 'http://localhost:8095/';

export const googleAppId =
    '98523387641-rdv6hi6t6vcnuqkoejkga0co10ctd4p6.apps.googleusercontent.com';

export const front_end_url =
    current_env === 'production'
        ? 'https://learn.terriblytinytales.com/'
        : 'https://devlearn.terriblytinytales.com/';
