import React, { useEffect, useState } from "react";
import { useApp } from "../context";

import {
    Spinner,
    Flex,
    Text,
    Box,
    Table,
    Tbody,
    Tr,
    Td,
    Button,
    Input
} from '@chakra-ui/react'
import MailTemplateModal from "../components/MailTemplates/MailTemplateModal";
import ViewTemplateModal from "../components/MailTemplates/ViewTemplateModal";
import JSZip from 'jszip';

const MailTemplates = () => {
    const app = useApp();

    const [mailTemplates, setMailTemplates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedMailTemplate, setSelectedMailTemplate] = useState({});
    const [reset, setReset] = useState(0);
    const [viewTemplate, setViewTemplate] = useState(false);

    const getTemplates = async () => {
        setLoading(true);

        const response = await app.helloFambase(
            'GET',
            `dashboard/list-mail-templates`
        )

        console.log(response)
        setLoading(false);

        if(response.success){
            const filteredData = response.data.filter((template) => template.template.trim() !== '');
            setMailTemplates(filteredData);
        }
    }

    useEffect(() => {
        getTemplates();
    }, [reset])

    const buttonClickHandler = (index) => {
        setShowModal(true);
        setSelectedMailTemplate(mailTemplates[index]);
    }

    const viewTemplateHandler = (index) => {
        setSelectedMailTemplate(mailTemplates[index]);
        setViewTemplate(true);
    }

    const exportTemplate = (index) => {
        let a = document.createElement('a');

        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(mailTemplates[index].template);
        a.download = `${mailTemplates[index].templateName}.html`;

        a.click();
    }

    const exportAll = async () => {
        const zip = new JSZip();
        mailTemplates.forEach((template) => {
            zip.file(`${template.templateName}.html`, template.template);
        });

        
        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = "mail-templates.zip";
        link.click();
    }

    return (
        <>
            <Text fontSize="3xl" align="center" mt="5">Mail Templates</Text>
            <Flex width="100%" justify="center" mt="5">
                <Input  width="50%" placeholder="Search Templates"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                 />
            </Flex>
            
            <Flex width="15%" direction="column">
                <Button mt="10" colorScheme="teal" onClick={() => {setShowModal(true); setSelectedMailTemplate({}) }}>Add Template</Button> 
                <Button mt="5" colorScheme="blue" onClick={()=>{exportAll()}}>Export All</Button>
            </Flex>

            { loading ? 
                <Flex align="center" justify="center" w="100%" h="100%" >
                    <Spinner /> 
                </Flex>
            : 
                <>
                    <Box w="65%" h="80%" align="center" m="auto" mt="-24" overflow="scroll" css={{
                        '&::-webkit-scrollbar': {
                            width: '0px',
                        },
                    }}>
                        <Table>
                            <Tbody>
                                {mailTemplates.filter((template) => template.templateName.toLowerCase().includes(searchQuery.toLowerCase()))
                                .length === 0 &&
                                    <Tr>
                                        <Td textAlign="center" border="1px" colSpan="4">No Templates Found</Td>
                                    </Tr>
                                }
                                {mailTemplates.filter((template) => template.templateName.toLowerCase().includes(searchQuery.toLowerCase()))
                                .map((template, index) => {
                                    return (
                                        <Tr textAlign="center" key={index}>
                                            <Td textAlign="center" border="1px">{template.templateName}</Td>
                                            <Td width="40" textAlign="center" border="1px"><Button onClick={buttonClickHandler.bind('this', index)} colorScheme="green">Update</Button></Td>
                                            <Td width="40" textAlign="center" border="1px"><Button onClick={viewTemplateHandler.bind('this', index)} colorScheme="yellow">View</Button></Td>
                                            <Td width="40" textAlign="center" border="1px"><Button onClick={exportTemplate.bind('this', index)} colorScheme="red">Export</Button></Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                </>
            }

            {showModal && 
                <MailTemplateModal
                    isOpen={true}
                    onClose={() => {setShowModal(false); setReset(reset + 1)}}
                    templateData={selectedMailTemplate}
                />
            }

            <ViewTemplateModal isOpen={viewTemplate} onClose={() => {setViewTemplate(false)}} templateData={selectedMailTemplate} />
        </>
    )
}

export default MailTemplates;