import React, { useState } from 'react';

const useProvideAuth = () => {
  const [user, setUser] = useState(localStorage.getItem('user'));
  const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));
  const [roles, setRoles] = useState(localStorage.getItem('roles'));
  const [subRoles, setSubRoles] = useState(localStorage.getItem('subRoles'));

  const signIn = (
    callback = () => {},
    payload = { user: '', userToken: '', roles: '',  subRoles: ''}
  ) => {
    return authHelpers.signIn(() => {
      console.log(payload);
      setUser(payload.user);
      setUserToken(payload.userToken);
      setRoles(payload.roles);
      setSubRoles(payload.subRoles);
      localStorage.setItem('user', payload.user);
      localStorage.setItem('userToken', payload.userToken);
      localStorage.setItem('roles', payload.roles);
      localStorage.setItem('subRoles', payload.subRoles);
      callback();
    });
  };

  const signOut = (callback = () => {}) => {
    return authHelpers.signOut(() => {
      setUser(null);
      setUserToken(null);
      setRoles(null);
      setSubRoles(null);
      localStorage.removeItem('user');
      localStorage.removeItem('userToken');
      localStorage.removeItem('roles');
      localStorage.removeItem('subRoles');
      callback();
    });
  };

  return {
    user,
    userToken,
    roles,
    subRoles,
    signIn,
    signOut,
  };
};

const authHelpers = {
  isAuthenticated: false,
  signIn(callback) {
    authHelpers.isAuthenticated = true;
    setTimeout(callback, 100);
  },
  signOut(callback) {
    authHelpers.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export default useProvideAuth;
