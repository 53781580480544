import React, { useRef, useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Text,
    Input,
    Button,
    Spinner,
    Flex
} from '@chakra-ui/react';

import Papa from 'papaparse';
import papaparse from 'papaparse';

import { useUtils } from '../../context';

const BatchUploadModal = ({open, close, name, segmentName}) => {
    const inputRef = useRef();
    const utils = useUtils();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [resultData, setResultData] = useState(false);

    const downloadSampleFile = () => {
        const downloadData = [
            [
                'Email',
                'Name'
            ],
        ];

        let csvString = papaparse.unparse(downloadData);
        csvString = `data:text/csv;charset=utf-8,${csvString}`;

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvString));
        
        const today = new Date();

        link.setAttribute(
            'download',
            `${today.toLocaleDateString()}_sample_for_import.csv`,
        );
        link.click();
    } 

    const uploadHandler = () => {
        console.log('File - ',inputRef.current.files[0]);
        setError(false);

        try{
            const file = inputRef.current.files[0];

            if(file){
                setLoading(true);

                Papa.parse(file, {
                    complete: async (data) => {
                        if(
                            data &&
                            data.data &&
                            Array.isArray(data.data) &&
                            data.data.length > 1
                        ){
                            console.log(data.data);

                            if((data.data[0][0].toLowerCase() === 'email') &&
                                (data.data[0][1].toLowerCase() === 'name')
                            ){
                                let result = await utils.onMembersImport(data.data, file.name, segmentName);
                                console.log('Result - ', result);

                                setResultData(result);
                                setLoading(false);
                            }

                            else{
                                setError(true);
                                setLoading(false);
                            }
                        }
                    },

                    error: () => {
                        setError(true);
                        setLoading(false);

                        if (inputRef && inputRef.current)
                            inputRef.current.value = '';
                    }
                })
            }
        }

        catch(err){
            setError(true);
        }

    }

    return(
        <Modal size="4xl" isOpen={open} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Batch Upload for &emsp;-&emsp; promo_{name}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody ml="5" pb="10">
                    <Text mt="10" size="md" mb="5px">Accepted Format - Email, Name (Name is optional)</Text>
                    <Text mt="5" size="md" mb="5px">File Format Accepted - .csv only </Text>

                    <Button mt="5" size="md" mb="5px" onClick={downloadSampleFile}>Download Sample File</Button>

                    <Flex mt="10">
                        <Input 
                            type="file"  
                            id="file" 
                            name="file" 
                            placeholder={"Your file ..."}
                            style={{ cursor: "pointer", textAlign: "center" }}
                            accept=".csv"
                            width="40%"
                            ref={inputRef}
                        />
                        <Button onClick={uploadHandler} ml="10" colorScheme="red">{loading ? <Spinner /> : 'Upload'}</Button>
                    </Flex>

                    {error ? 
                        <Text color="red.500" mt="10" size="md">Error Occurred</Text>
                        :
                        resultData ?
                        <Text color="lightgreen" mt="10" size="md">Uploaded Successfully</Text>
                        :
                        null
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BatchUploadModal;