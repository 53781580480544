import React, {useEffect, useState} from 'react';

import {
    Box,
    Text,
    Button,
    Input,
    Flex,
    Spinner,
    Center,
    CircularProgress,
    InputGroup,
    InputRightAddon,
    InputLeftAddon
} from '@chakra-ui/react';

import { SinglePagePagination } from '../../components/ui-molecules';
import { useApp } from '../../context';
import MailingListTable from './MailingListTable';
import MailingListDataModal from './MailingListDataModal';
import { environment } from '../../config';

const Search = () => {
    const [mailingLists, setMailingLists] = useState([]);
    const [currentMailingLists, setCurrentMailingLists] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [offset, setOffset] = useState(0);
    const [dataLoading, setDataLoading] = useState(false);
    const [nextList, setNextList] = useState(null);
    // const [userId, setUserId] = useState(0);
    // const [planId, setPlanId] = useState(0);
    const [segmentName, setSegmentName] = useState("");
    const [mailingListData, setMailingListData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, showError] = useState(false);
    

    const app = useApp();

    const getMailingList = async () => {
        setDataLoading(true);
        const data = {
            offset: 0,
            limit: 20,
            nextList: nextList,
        }

        const response = await app.helloFambase(
            'POST',
            'dashboard/list-mailing-lists',
            data
        )
        
        setDataLoading(false);
        console.log(response);
        if(response.success){
            setMailingLists([...mailingLists, ...response.data.items]);
            setNextList(response.data.paging.next);
        }
    }

    useEffect(() => {
        if (pageNumber * 20 >= mailingLists.length) {
            getMailingList();
        }
    }, [offset])

    useEffect(() => {
        setCurrentMailingLists(mailingLists.slice(20 * (pageNumber - 1), 20 * pageNumber))
    }, [mailingLists, pageNumber])

    // const userIdChangeHandler = (e) => {
    //     setUserId(e.target.value);
    // }

    // const planIdChangeHandler = (e) => {
    //     setPlanId(e.target.value);
    // }

    const segmentChangeHandler = (e) => {
        setSegmentName(e.target.value);
    }

    const submitHandler = async () => {
        showError(false);
        setLoading(true);

        const data = {
            name: segmentName
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/search-mailing-list',
            data
        )
        console.log(response);
        setLoading(false);

        if(response.success && response.data === "Not Found") showError(true);
        else if(response.success){
            setMailingListData(response.data.list);
        }
    }

    return(
        <Box mt="10" ml="20" mr="40" height="100%">
            <Flex direction="column">
                <Text size="md" mb="8px">Enter mailing list name (plan_planId || promo_all_subs)</Text>
                <Flex>
                    <InputGroup>
                        {/* <InputLeftAddon></InputLeftAddon> */}
                        <Input onChange={segmentChangeHandler} placeholder="List name (eg. all, all_creators)" size="md" />
                        <InputRightAddon>_mailing_list@mg1.{environment === "production" ? 'mail.terriblytinytales.com' : 'tdingapp.com'}</InputRightAddon>
                    </InputGroup>
                    <Button size="md" ml="5" colorScheme="red" onClick={submitHandler}>
                        {loading ? <Spinner /> : "Submit"}
                    </Button>
                </Flex>
            </Flex>

            { error && <Text size="md" mb="8px" mt="10" color="red">No List Found</Text>}

            { dataLoading ? <Center h="50%"><CircularProgress isIndeterminate color="red.400" /> </Center> : <MailingListTable mailingLists={currentMailingLists}/> }

            <SinglePagePagination 
                pageNumber={pageNumber}
                onLeftClick={() => {
                    if (pageNumber > 1) {
                        setPageNumber(pageNumber - 1);
                    }
                }}
                onRightClick={() => {
                    if (mailingLists.length > 0) {
                        setOffset(offset + 20);
                        setPageNumber(pageNumber + 1);
                    }
                }}
            />

            { !error && mailingListData &&
                <MailingListDataModal mailingListData={mailingListData} closeModal={() => setMailingListData(null)} />                        
            }
        </Box>
    )
}

export default Search;