import React from 'react';

import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
} from '@chakra-ui/react';

import MailingListDataModal from './MailingListDataModal';

const TH = ({ children }) => {
    return <Th border="1px" textAlign="center">{children}</Th>;
};

const TD = ({ children, onClick }) => {
    return <Td onClick={onClick} border="1px" textAlign="center" _hover={{'cursor':'pointer'}}>{children}</Td>;
};



const MailingListTable = ({mailingLists}) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [data, setData] = React.useState();

    const mailingListDataModalHandler = (data) => {
        setData(data);
        setOpenModal(true);
    }

    return(
        <>
            <Box
                h="60%"
                oveflow="scroll"
                mb="5"
                mt="10"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '0px',
                    },
                    'overflow': 'scroll'
                }}
            >
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <TH>Mailing List</TH>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            mailingLists.map((mailingList, index) => {
                                return(
                                    <Tr key={index}>
                                        <TD onClick={mailingListDataModalHandler.bind('this', mailingList)}>{mailingList.address}</TD>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                    {mailingLists.length === 0 && (
                        <TableCaption>No Lists found</TableCaption>
                    )}
                </Table>
            </Box>   
            { openModal && data &&
                <MailingListDataModal mailingListData={data} closeModal={() => setOpenModal(false)}/>
            }
        </>
    )
}

export default MailingListTable;