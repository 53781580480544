import React, { useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box
} from '@chakra-ui/react';

const ViewTemplateModal = ({ isOpen, onClose, templateData }) => {
    return(
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{templateData.templateName}</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Box h="100%" dangerouslySetInnerHTML={{ __html: templateData.template }} />
                </ModalBody>

            </ModalContent>

        </Modal>
    )   
}

export default ViewTemplateModal;