import React, { useState,useRef  } from 'react';

import {
    Center,
    Box,
    Flex,
    Text,
    Input,
    Button,
    Spinner,
    useToast,
} from '@chakra-ui/react';
import { useUtils, useApp } from '../context';
import Compress from 'compress.js';

const AddHiringPartner = () => {
    const utils = useUtils();
    const toast = useToast();
    const app = useApp();
    
    const formRef = useRef(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [company, setCompany] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (!name || !email || !company || !file) {
            return toast({
                title: 'Error',
                description: 'Name, Email, Company Name, and File are required',
                status: 'error',
            });
        }

        if (
            fileType !== 'image/png' &&
            fileType !== 'image/jpg' &&
            fileType !== 'image/jpeg'
        ) {
            return toast({
                title: 'Error',
                description: 'File must be PNG, JPG, or JPEG format.',
                status: 'error',
            });
        }

        const resizedFile = await resizeImage(file);
        handleFileUpload(resizedFile);
    };

    async function resizeImage(file) {
        const compress = new Compress();
        const resizedImage = await compress.compress([file], {
            size: 1,
            quality: 1,
            maxWidth: 512,
            maxHeight: 512,
            resize: true,
        });

        const img = resizedImage[0];
        const base64str = img.data;
        const imgExt = img.ext;

        let resizedWidth = 512;
        let resizedHeight = 512;

        if (img.width > img.height) {
            resizedWidth = 512;
            resizedHeight = img.height * (512 / img.width);
        } else if (img.height > img.width) {
            resizedWidth = img.width * (512 / img.height);
            resizedHeight = 512;
        }

        const resizedFiile = Compress.convertBase64ToFile(
            base64str,
            imgExt,
            resizedWidth,
            resizedHeight
        );
        return resizedFiile;
    }

    const handleFileUpload = async (resizedFile) => {
        try {
            const fileExtension = fileType.split('/').pop();
            const companyNameWithoutSpaces = company.replace(/\s+/g, '-');
            const newFileName = `${companyNameWithoutSpaces}-${Date.now()}.${fileExtension}`;
            setLoading(true);
            const link = await utils.uploadToS3v2(
                resizedFile,
                newFileName,
                fileType
            );
            console.log('Link: ', link);

            if (link) {
                const obj = {
                    name,
                    email,
                    mobileNo: mobile,
                    whatsappNo: whatsapp,
                    company,
                    link,
                };

                const result = await app.helloFambase(
                    'POST',
                    'dashboard/addHiringPartner',
                    obj
                );
                console.log('result: ', result);
                if (result.success) {
                    toast({
                        title: 'Success',
                        description: 'Hiring Partner added successfully',
                        status: 'success',
                    });
                }
            }

            setLoading(false);
            setName('');
            setEmail('');
            setMobile('');
            setWhatsapp('');
            setCompany('');
            setFile(null);
            setFileName('');
            setFileType('');
            formRef.current.reset(); 
        } catch (err) {
            console.log(err);

            setLoading(false);
            toast({
                title: 'Error',
                description: 'Upload Failed. Try again later.',
                status: 'error',
            });
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileExtension = selectedFile.name
                .split('.')
                .pop()
                .toLowerCase();
            if (
                fileExtension !== 'png' &&
                fileExtension !== 'jpg' &&
                fileExtension !== 'jpeg'
            ) {
                toast({
                    title: 'Error',
                    description: 'File must be PNG, JPG or JPEG format.',
                    status: 'error',
                });
                return;
            }

            setFile(selectedFile);
            setFileName(selectedFile.name);
            setFileType(selectedFile.type);
        }
    };

    return (
        <Box h="100%">
            <form ref={formRef}>
                <Center>
                    <Text fontSize={'3xl'}>Add Hiring Partner</Text>
                </Center>
                <Flex mt="10" align={'center'}>
                    <Text width="12%">
                        Name<span style={{ color: 'red' }}>*</span> :
                    </Text>
                    <Input
                        onChange={e => setName(e.target.value)}
                        width="60%"
                        placeholder="Name"
                        value={name}
                        type="text"
                    />
                </Flex>
                <Flex mt="10" align={'center'}>
                    <Text width="12%">
                        Email<span style={{ color: 'red' }}>*</span> :
                    </Text>
                    <Input
                        onChange={e => setEmail(e.target.value)}
                        width="60%"
                        placeholder="Email"
                        value={email}
                        type="email"
                    />
                </Flex>
                <Flex mt="10" align={'center'}>
                    <Text width="12%">Phone :</Text>
                    <Input
                        onChange={e => setMobile(e.target.value)}
                        width="60%"
                        placeholder="Phone"
                        value={mobile}
                        type="text"
                    />
                </Flex>
                <Flex mt="10" align={'center'}>
                    <Text width="12%">Whatsapp :</Text>
                    <Input
                        onChange={e => setWhatsapp(e.target.value)}
                        width="60%"
                        placeholder="Whatsapp"
                        value={whatsapp}
                        type="text"
                    />
                </Flex>
                <Flex mt="10" align={'center'}>
                    <Text width="12%">
                        Company Name<span style={{ color: 'red' }}>*</span> :
                    </Text>
                    <Input
                        onChange={e => setCompany(e.target.value)}
                        width="60%"
                        placeholder="Company Name"
                        value={company}
                        type="text"
                    />
                </Flex>

                <Flex mt="10" align={'center'}>
                    <Text width="12%">
                        Select File<span style={{ color: 'red' }}>*</span> :
                    </Text>
                    <Input
                        width="50"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                    />
                </Flex>

                {loading ? (
                    <Flex mt="10">
                        <Spinner />
                    </Flex>
                ) : (
                    <Button
                        onClick={handleSubmit}
                        width="18%"
                        mt="10"
                        colorScheme="green"
                    >
                        Add Hiring Partner
                    </Button>
                )}
            </form>
        </Box>
    );
};

export default AddHiringPartner;
