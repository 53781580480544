import React from 'react';

import {
    Box, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, Button,
    AlertDialogCloseButton,
} from '@chakra-ui/react';


const ErrorDialog = ({ isOpen, onOpen, onClose, errorMessage }) => {
    const cancelRef = React.useRef()

    return (
        <Box>
            <AlertDialog
                motionPreset="slideInBottom"
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Error!</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {errorMessage}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button colorScheme="red" ml={3} ref={cancelRef} onClick={onClose}>
                            Okay
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

        </Box>
    )
}

export default ErrorDialog;
