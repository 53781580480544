import React, { useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Box,
    Text,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Button,
    Spinner,
    Flex,
    Textarea

} from '@chakra-ui/react';

import { useApp } from '../../context';
import { environment } from '../../config';

import ViewTemplateModal from './ViewTemplateModal';

const MailTemplateModal = ({isOpen, onClose, templateData}) => {
    const oldTemplateData = templateData || {};
    const [templateName, setTemplateName] = useState(templateData.templateName || '');
    const [template, setTemplate] = useState(templateData.template || '');
    const [footerTemplate, setFooterTemplate] = useState(templateData.footer || '');
    const [trackerInfo, setTrackerInfo] = useState(templateData.trackerInfo || '');
    const [subject, setSubject] = useState(templateData.subject || '');
    const [fromName, setFromName] = useState(templateData.fromName || '');
    const [fromEmail, setFromEmail] = useState(templateData.fromMail || '');
    const [query, setQuery] = useState(templateData.query || '');
    const [mailingListName, setMailingListName] = useState(templateData.mailingListName || '');
    const [bcc, setBcc] = useState(templateData.bcc || '');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setshowSuccess] = useState(false);
    const [showError, setshowError] = useState(false);
    const [viewTemplate, setViewTemplate] = useState(false);
    const [showSanitiseSuccess, setShowSanitiseSuccess] = useState(false);

    const app = useApp();

    const submitHandler = async () => {
        setshowError(false);
        setshowSuccess(false);

        if(validate()){
            setLoading(true);

            const data = {
                templateName: templateName,
                template: template,
                footer: footerTemplate,
                trackerInfo: trackerInfo,
                subject: subject,
                fromName: fromName,
                fromMail: fromEmail,
                query: query,
                mailingListName: mailingListName,
                bcc: bcc,
                oldTemplateData: oldTemplateData
            }

            const response = await app.helloFambase(
                'POST',
                `dashboard/update-mail-template`,
                data
            )
            console.log(response);
            setLoading(false);

            if(response.success){
                setshowSuccess(true);
                setshowError(false);
            }
            else{
                setshowError(true);
                setshowSuccess(false);
            }
        }

        else return;
    }

    const validate = () => {
        if(!templateName) { 
            alert('Fill Template Name'); 
            return false; 
        }

        if(!template) {
            alert('Fill Template');
            return false;
        }

        if(!subject) {
            alert('Fill Subject');
            return false;
        }

        if(!fromName) {
            alert('Fill From Name');
            return false;
        }

        if(!fromEmail) {
            alert('Fill From Email');
            return false;
        }

        return true;
    }

    const sanitiseTemplate = () => {
        setShowSanitiseSuccess(false);

        if(!template){
            alert('Enter Template');
            return;
        }

        else{
            let oldTemplate = template;

            let unsubLink = `https://learn.terriblytinytales.com/email/unsubscribe?type=promo`;
            oldTemplate = oldTemplate.replace("[[UNSUB_LINK_EN]]", unsubLink)
        
            oldTemplate = oldTemplate.replace("<u>support@mail.terriblytinytales.com</u>", `<a href="mailto:support@mail.terriblytinytales.com"><u>support@mail.terriblytinytales.com</u></a>`);

            setTemplate(oldTemplate);

            let oldFooterTemplate = footerTemplate;

            oldFooterTemplate = oldFooterTemplate.replace("[[UNSUB_LINK_EN]]", unsubLink)
            oldFooterTemplate = oldFooterTemplate.replace("<u>support@mail.terriblytinytales.com</u>", `<a href="mailto:support@mail.terriblytinytales.com"><u>support@mail.terriblytinytales.com</u></a>`);

            setFooterTemplate(oldFooterTemplate);

            setShowSanitiseSuccess(true);
        }
    }

    return(
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add/Edit Template</ModalHeader>
                <ModalCloseButton />
                
                <ModalBody>
                    <Box mt="5" ml="10" mr="10" height="100%">
                        <Flex align="flex-start" justify="space-between">
                            <Flex direction="column" mr="10">
                                <Text size="md" mb="8px">Enter Template Name *</Text>
                                <InputGroup>
                                {mailingListName && <InputLeftAddon>BROADCAST_</InputLeftAddon> }
                                    <Input type="text" isRequired value={templateName.replace('BROADCAST_', '')} onChange={(e) => {setTemplateName((mailingListName ? 'BROADCAST_' : '') + e.target.value.replace(' ', '_').toUpperCase()); setshowSuccess(false); setshowError(false)}} placeholder="Enter Template Name"/>
                                </InputGroup>
                            </Flex>

                            <Text mb="5">* are mandatory fields</Text>
                            {/* <Button onClick={() => setViewTemplate(true)} colorScheme="green">View Template</Button> */}
                        </Flex>

                        <Text mt="7" size="md" mb="8px">Enter Template *</Text>
                        <Textarea isRequired value={template} onChange={(e) => setTemplate(e.target.value)} placeholder="Enter Template" />

                        <Text mt="7" size="md" mb="8px">Enter Footer Template</Text>
                        <Textarea isRequired value={footerTemplate} onChange={(e) => setFooterTemplate(e.target.value)} placeholder="Enter Footer Template" />

                        <Text mt="7" size="md" mb="8px">Enter Query</Text>
                        <Textarea isRequired value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Enter Query" />

                        <Text mt="7" size="md" mb="8px">Enter Tracker Info</Text>
                        <Textarea isRequired value={trackerInfo} onChange={(e) => setTrackerInfo(e.target.value)} placeholder="Enter Tracker Info" />

                        <Text mt="7" size="md" mb="8px">Enter Subject *</Text>
                        <Input placeholder="Enter Subject" value={subject} isRequired onChange={(e) => setSubject(e.target.value)} />

                        <Flex mt="7">
                            <Flex direction="column" mr="10">
                                <Text size="md" mb="8px">Enter From Name *</Text>
                                <Input placeholder="Enter From Name" value={fromName} isRequired onChange={(e) => setFromName(e.target.value)} />
                            </Flex>

                            <Flex direction="column">
                                <Text size="md" mb="8px">Enter From Email *</Text>
                                <InputGroup>
                                    <Input placeholder="Enter From Email" value={fromEmail} isRequired onChange={(e) => setFromEmail(e.target.value)} />
                                    <InputRightAddon>@mail.terriblytinytales.com</InputRightAddon>
                                </InputGroup>
                            </Flex>
                        </Flex>

                        <Text mt="7" size="md" mb="8px">Enter Mailing List Name</Text>
                        <InputGroup>
                            <Input placeholder="Enter Mailing List" value={mailingListName} isRequired onChange={(e) => setMailingListName(e.target.value.toLowerCase())} />
                            <InputRightAddon>_mailing_list@mg1.{environment === 'production' ? 'mail.terriblytinytales' : 'tdingapp'}.com</InputRightAddon>
                        </InputGroup>
                        <Text mt="2">(If mailing list name added, then BROADCAST_ will be auto appended in mail template name)</Text>

                        <Text mt="7" size="md" mb="8px">Enter Bcc</Text>
                        <Input placeholder="Enter Bcc" value={bcc} isRequired onChange={(e) => setBcc(e.target.value)} />

                        <Flex mt="7" align="center">
                            <Button colorScheme="green" onClick={sanitiseTemplate}>Sanitise Template</Button>
                            {showSanitiseSuccess && <Text ml="5" color="green">Success</Text>}
                        </Flex>
                        <Text mt="2">(Click after filling all the details)</Text>

                        <Flex mt="7" justify="space-between">
                            { showSuccess ? 
                                <Text size="md" mb="8px" mt="10" color="lightgreen">Added/Updated Successfully</Text>
                            :
                                showError ?
                                    <Text size="md" mb="8px" mt="10" color="red">Error</Text>
                                :
                                    <Button mb="10" onClick={submitHandler} mt="10" colorScheme="red" variant="solid" size="md">
                                        {loading ? <Spinner /> : 'Submit'}
                                    </Button>
                            }
                        </Flex>

                    </Box>

                </ModalBody>
            </ModalContent>
            <ViewTemplateModal isOpen={viewTemplate} onClose={() => setViewTemplate(false)} templateData={templateData} />
        </Modal>
    )
}

export default MailTemplateModal;