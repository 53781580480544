import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from '../../context';

import { ColorModeSwitcher } from '../../ColorModeSwitcher';

import {
  Heading,
  Button,
  Box,
  Flex,
  Link,
  Spacer,
  Divider,
  IconButton
} from '@chakra-ui/react';
import { CloseIcon, ArrowLeftIcon } from '@chakra-ui/icons'

import { ExternalLinkIcon } from '@chakra-ui/icons';

import SidebarItem from './SidebarItem';

import Routes from '../../routing/routes';

const Sidebar = ({ routes, onToggle, closeMenu }) => {
  const auth = useAuth();
  const history = useHistory();

  const [activeButton, setActiveButton] = useState();

  useEffect(() => {
    if(activeButton === "Project Evaluations") {
      closeMenu();
    }
  }, [activeButton])

  return (
    <Flex direction="column" minH="100%" w="20%" minW="300px" m={2}>
      <Box p={4} bg="red.400" boxShadow="xl" rounded="md">
        <Flex direction="row" justify="space-between" >
        <Heading textAlign="left" color="white">
          TTT Academy
        </Heading>
        <IconButton aria-label='Search database' icon={<ArrowLeftIcon />} onClick={onToggle} />
        </Flex>
      </Box>
      {/* <Divider /> */}
      <Flex
        grow={1}
        direction="column"
        p={4}
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        {Routes.map((route, index) => {
          if(auth.roles === "admin" || (auth.subRoles === "instructor" && route.name === "Project Evaluations" || auth.subRoles === "reports" && route.name === "Dynamic Reports" || auth.subRoles === "image-reviewer" && route.name === "Review Images")) {
            return (
              <SidebarItem
                item={route.name}
                key={index}
                isActive={activeButton === route.name}
                handleClick={() => {
                  setActiveButton(route.name);
                  history.push(route.path);
                }}
              />
            );
          }
          return null;
        })}
      </Flex>
      <Divider size="divide" />
      <Flex p={4} width="100%">
        <Button
          colorScheme="red"
          variant="ghost"
          onClick={() => auth.signOut()}
        >
          Sign Out
        </Button>
        <Spacer />
        <Button colorScheme="red" variant="ghost">
          <Link
            href="https://fambase.com/"
            isExternal
            m="auto"
          >
            TTT Academy <ExternalLinkIcon mx="2px" />
          </Link>
        </Button>
        <Spacer />
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
    </Flex>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.any,
  onToggle: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Sidebar;
