import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useApp } from '../context';

import {
  Box,
  Heading,
  Flex,
  Spinner
} from '@chakra-ui/react';

import { Search } from '../components/ui-molecules';

import { UserProfileCard } from '../components/User';

const SearchUsers = () => {
  const app = useApp();
  const location = useLocation();

  const [userDetails, setUserDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let param = new URLSearchParams(location.search).get('q');
    if (param) {
      getUserDetails(param);
    }
  }, []);

  const getUserDetails = async userIdentifier => {
    console.log(userIdentifier);
    setLoading(true);

    let obj = {
      searchParam: userIdentifier,
      limit: 20,
      offset: offset,
    };

    let res = await app.helloFambase('POST', `dashboard/searchUser`, obj);
    
    console.log(res);
    setLoading(false);

    if (res.success) {
      setUserDetails([]);
      let NewDetails = res.data.data;
      setUserDetails(NewDetails);
    }
  };

  return (
    <Flex direction="column" h="100%">
      <Heading mx="auto" size="lg">
        Search Users.
      </Heading>
      <Search
        placeholder="Search user with Email and User ID"
        handleSearch={state => getUserDetails(state)}
      />

      {loading ? 
        <Flex justify="center">
          <Spinner />
        </Flex>
      :
        null
      }

      <Box
        h="100%"
        overflow="scroll"
        mb="5"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex flexWrap="wrap">
          {userDetails.map((item, index) => (
            <UserProfileCard profileObject={item} key={index} />
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};

export default SearchUsers;
