import React, { useState } from 'react';
import { useApp } from '../context';
import { Box, Button, Flex, FormLabel, Heading, Spinner, Text, Textarea } from '@chakra-ui/react';

const GenerateImage = () => {
    const app = useApp();

    const [prompt, setPrompt] = useState('');
    const [size, setSize] = useState('1792x1024'); // ['1792x1024', '1024x1024', '1024x1792'
    const [quality, setQuality] = useState('standard'); // ['standard', 'hd']
    const [style, setStyle] = useState('vivid'); // ['vivid', 'natural']
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        try{
            setLoading(true);
            setResult(null);

            const data = await app.helloFambase(
                'POST',
                'dashboard/generate-image',
                {
                    prompt,
                    size,
                    quality,
                    style
                }
            )

            console.log(data);

            setLoading(false);

            if(data.success){
                setResult(data.data.data[0].url);
            }

        } catch(err){
            console.log(err);
        }
    }

    return(
        <Box h="100%">
            <Heading textAlign={'center'}>Generate Image</Heading>
        
            <Flex mt="10">
                <FormLabel>Prompt</FormLabel>
                <Textarea ml="5" height={40} placeholder='Enter prompt' value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </Flex>

            <Flex mt="10">
                <Flex>
                    <FormLabel>Size</FormLabel>
                    <select name="size" id="size" value={size} onChange={(e) => setSize(e.target.value)}>
                        <option value="1792x1024" selected>1792x1024</option>
                        <option value="1024x1024">1024x1024</option>
                        <option value="1024x1792">1024x1792</option>
                    </select>

                    <Text ml="5">{size}</Text>
                </Flex>

                <Flex ml="40">
                    <FormLabel>Quality</FormLabel>
                    <select name="quality" id="quality" value={quality} onChange={(e) => setQuality(e.target.value)}>
                        <option value="standard" selected>standard</option>
                        <option value="hd">hd</option>
                    </select>

                    <Text ml="5">{quality}</Text>
                </Flex>

                <Flex ml="40">
                    <FormLabel>Style</FormLabel>
                    <select name="style" id="style" value={style} onChange={(e) => setStyle(e.target.value)}>
                        <option value="vivid" selected>vivid</option>
                        <option value="natural">natural</option>
                    </select>

                    <Text ml="5">{style}</Text>
                </Flex>
            </Flex>

            {loading ? <Spinner mt="10" /> : <Button mt="10" colorScheme='blue' onClick={submitHandler}>Submit</Button>}
        
            {result && <Button mt="10" ml="20" colorScheme='green' onClick={() => window.open(result)}>Preview</Button>}
        </Box>
    )
}

export default GenerateImage;