import React, { useEffect, useState } from 'react';

import { 
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Input,
    Button,
    Center
} from '@chakra-ui/react';

const EditVariablesModal = ({isOpen, onClose, templateData, onUpdate}) => {
    const [template, setTemplate] = useState(templateData.template);
    const [variables, setVariables] = useState([]);
    const [updatedValues, setUpdatedValues] = useState([]);
    
    useEffect(() => {
        let pattern = /{{[A-Za-z0-9-_]{1,}}}/g;
        let matches = templateData.template.match(pattern);
        // console.log('matches - ', templateData.template);
        if(matches){
            matches = matches.map((match) => {
                return match.replace('{{', '').replace('}}', '');
            })
        }
        
        console.log(matches)
        setVariables(matches);
    }, [templateData])

    const updateValueHandler = (e, index) => {
        let updatedValuesCopy = [...updatedValues];
        updatedValuesCopy[index] = e.target.value;

        setUpdatedValues(updatedValuesCopy);
    }

    const saveHandler = () => {
        let updatedTemplate = template;

        variables.forEach((variable, index) => {
            updatedTemplate = updatedTemplate.replace(`{{${variable}}}`, updatedValues[index]);
        })

        setTemplate(updatedTemplate);

        let updatedTemplateData = {...templateData};
        updatedTemplateData.template = updatedTemplate;

        onUpdate(updatedTemplateData);
        onClose();
    }
    
    return(
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Edit Variables</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    {variables && variables.map((variable, index) => {
                        return(
                            <div key={index}>
                                <Text key={index} mt="10">{variable}</Text>
                                <Input mt="3" type="text" placeholder={variable} value={updatedValues[index]} onChange={(e) => updateValueHandler(e, index)}/>
                            </div>
                        )
                    })}

                    {variables && <Center><Button onClick={saveHandler} width="60" mt="10" mb="10" colorScheme="green" >Save</Button></Center> }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default EditVariablesModal;