import React, { useState, useEffect } from 'react';

import {
  Center,
  Box,
  Flex,
  Image,
  Badge,
  Text,
  Avatar,
  Button,
  Input
} from '@chakra-ui/react';

import SuspendUser from './SuspendUser';
import MailHistory from './MailHistory';
import PlanDetails from './PlanDetails';
import SubscriberTransactions from './SubscriberTransactions';
import CustomBg from './CustomBG';
import CustomBanner from './CustomBanner';
import PaidCustomBanner from './PaidCustomBanner';
import Posts from './Posts';
import Comment from './Comment';
import SubscribedTo from './SubscribedTo';
import ImportSubscriber from './ImportSubscriber';
import AddPlan from './AddPlan';
import AddCustomPlan from "./AddCustomPlan";
import UpdateBanner from './GenerateBanner';

const UserProfileCard = ({ profileObject }) => {
  const [userSuspendStatus, setUserSuspendStatus] = useState();
  const [profile, setProfile] = useState(profileObject);
  const [bg, setBg] = useState(profileObject.custombg);
  const [banner, setBanner] = useState(profileObject.custombanner);
  const [paidBanner, setPaidBanner] = useState(profileObject.paidcustombanner);
  useEffect(() => {
    console.log(profileObject.status);
    setUserSuspendStatus(profileObject.status);
  }, []);

  const updateProfile = (name, value) => {
    if (name == "custombg") setBg(value);
    if (name == "custombanner") setBanner(value);
    if(name == "paidcustombanner") setPaidBanner(value) ;
    let tempProfile = {...profile};
    tempProfile[name] = value;
    setProfile(tempProfile)
  }

  const updateGeneratedBanner = (url) => {
    let tempProfile = {...profile};
    tempProfile['custombanner'] = url;
    setProfile(tempProfile)
  }

  const ChangeUserSuspendStatus = status => {
    setUserSuspendStatus(status);
  };

  return (
    <Box w={320} borderWidth="1px" borderRadius="lg" overflow="hidden" m={5}>
      <Center mt={0} position="relative" w="100%" h="200px" backgroundImage={`url(${bg})`} backgroundRepeat="no-repeat" backgroundSize="cover">
        {profile.picture !== '' ? (
          <Image
            src={profile.picture}
            alt={profile.name}
            borderRadius="full"
            boxSize="100px"
            objectFit="cover"
            mt={20}
          />
        ) : (
          <Avatar
            name={profile.name}
            src={profile.picture}
            boxSize="70px"
            mt={20}
          />
        )}
      </Center>
      <Box p="6">
        <Box d="flex" alignItems="baseline">
          {profile.username ? (
            <Badge colorScheme="green">Creator</Badge>
          ) : (
            <Badge colorScheme="red">User</Badge>
          )}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          mt="2"
        >
          Subscribers-{' '}
          Free: {profile.freeSubscribers ? profile.freeSubscribers : '0'}{' '}
          Paid: {profile.paidSubscribers ? profile.paidSubscribers : '0'}{' '}
          Workshop: {profile.workshopSubscribers ? profile.workshopSubscribers : '0'}{' '}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          mt="2"
        >
          Total Subscribers: {profile.freeSubscribers + profile.paidSubscribers + profile.workshopSubscribers}{' '}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          mt="2"
        >
          Subscriptions-{' '}
          Free: {profile.freeSubscriptions ? profile.freeSubscriptions : '0'}{' '}
          Paid: {profile.paidSubscriptions ? profile.paidSubscriptions : '0'}{' '}
          Workshop: {profile.workshopSubscriptions ? profile.workshopSubscriptions : '0'}{' '}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          mt="2"
        >
          Total Subscriptions: {profile.freeSubscriptions + profile.paidSubscriptions + profile.workshopSubscriptions}{' '}
        </Box>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          mt="2"
        >
          Content Published :{' '}
          {profile.contentPublished
            ? profile.contentPublished
            : '0'}{' '}
        </Box>
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          User Id : {profile.id ? profile.id : '-'}{' '}
        </Box>
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          Name : {profile.name ? profile.name.toUpperCase() : '-'}{' '}
        </Box>
        <Box mt="1">
          <Text fontSize="sm">
            Email :{' '}
            {profile.email ? profile.email.toUpperCase() : '-'}
          </Text>
          <a href={`https://${profile.username}.fambase.com`} target="_blank" rel="noreferrer"><Text fontSize="sm">
            User Name : {profile.username ? profile.username : '-'}
          </Text></a>
          <Text fontSize="sm">
            Active since : {profile.createdAt ? profile.createdAt.slice(8, 10) + '-' + profile.createdAt.slice(5, 8) + profile.createdAt.slice(0, 4) : '-'}
          </Text>
        </Box>
        <Flex mt="1">
          {/* {
    "id": 1308,
    "name": "magicalbox52",
    "username": "magicman",
    "email": "magicalbox52@gmail.com",
    "picture": "",
    "status": 2,
    "freeSubscribers": 0,
    "paidSubscribers": 0,
    "workshopSubscribers": 0,
    "freeSubscriptions": 0,
    "paidSubscriptions": 0,
    "workshopSubscriptions": 0,
    "contentPublished": 1,
    "usersHeWasBlockedBy": 0,
    "usersHeblocked": 0,
    "likesGiven": 0,
    "likesReceieved": 0
} */}
          <Text fontSize="sm">
            Blocked By : {profile.usersHeWasBlockedBy}
          </Text>
          <Text ml="5" fontSize="sm">
            Blocked : {profile.usersHeblocked}
          </Text>
        </Flex>
        <Flex>
          {' '}
          <Text fontSize="sm">Likes Given : {profile.likesGiven}</Text>
          <Text ml="5" fontSize="sm">
            Likes Received : {profile.likesReceieved}
          </Text>
        </Flex>
        <Flex mt="2">
          <Text style={{'word-break' : 'break-all'}}>Social Link: <a href={`${profile.socialLink}`} target="_blank" rel='noreferrer'>{profile.socialLink}</a></Text>
        </Flex>
        <SuspendUser
          key={"Suspend_" + profile.id}
          userId={profile.id}
          status={userSuspendStatus}
          ChangeUserSuspendStatus={ChangeUserSuspendStatus}
        />
        {/* <MailHistory
          key={"mailHistory_" + profile.id}
          userId={profile.id}
        /> */}
        <ImportSubscriber
          key={"importSubscriber_" + profile.id}
          userId={profile.id}
        />
        <SubscribedTo
          key={"subscribedTo_" + profile.id}
          userId={profile.id} />
        {/* <PlanDetails
          key={"planDetails_" + profile.id}
          userId={profile.id}
        /> */}
        {/* <AddPlan
          key={"planDetails1_" + profile.id}
          userId={profile.id}
        /> */}
        {/* <AddCustomPlan 
          key={"customPlanDetails_" + profile.id}
          userId={profile.id}
        /> */}
        {/* <SubscriberTransactions
          key={"transactions_" + profile.id}
          userId={profile.id}
        /> */}
        {/* <Posts
          key={"posts_" + profile.id}
          userId={profile.id}
        /> */}
        {/* <Comment
          key={"comments" + profile.id}
          userId={profile.id}
        /> */}

        {/* <UpdateBanner profileObject={profileObject} updateGeneratedBanner={updateGeneratedBanner} /> */}

        {/* <CustomBg
          key={"CustomBg_" + profile.id}
          userId={profile.id}
          updateBg={updateProfile}
        /> */}
        {/* <Center>
          <Image
            w="260px"
            h="100px"
            mt="4"
            src={banner}
          />
        </Center>
        <CustomBanner
          key={"CustomBanner_" + profile.id}
          userId={profile.id}
          updateBg={updateProfile}
        /> */}
        
        {/* <Center>
          <Image
            w="260px"
            h="100px"
            mt="4"
            src={paidBanner}
          />
        </Center> */}
        {/* <PaidCustomBanner
          key={"PaidCustomBanner_" + profile.id}
          userId={profile.id}
          updateBg={updateProfile}
        /> */}
      </Box>
    </Box>
  );
};

export default UserProfileCard;
