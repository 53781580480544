import CouponCodesManagement from '../components/CouponCodes';
import { useEffect, useRef } from 'react';

function CouponCodes() {
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    if (!isMounted.current) return null;

    return <CouponCodesManagement />;
}

export default CouponCodes;
