import React, { useRef, useState, useEffect } from 'react';

import {
    Center, Box, Select, Flex, Button

} from '@chakra-ui/react';
import { useAuth } from '../context';
import ReportTable from '../components/DynamicReports/ReportTable'
import { useApp } from '../context';
import EditReportModal from '../components/DynamicReports/EditReportModal';

const DynamicReports = () => {
    const app = useApp();
    const auth = useAuth();
    const [currentType, setCurrentType] = useState('');
    const [options, setOptions] = useState([]);
    const [editReportModal, setEditReportModal] = useState(false);

    const getReportKey = async (type) => {
        console.log("TYPE ", type)
        setCurrentType(type)
    }

    useEffect(async () => {
        let response = await app.helloFambase(
            'GET',
            'dashboard/dynamicReportsOptions'
        );
        if (response.success) {
            console.log(response);
            setOptions(response.data)
        }
    }, [])

    return (
        <Box
            h="100%"
        >
            <Flex direction="column" h="100%">
                <Flex align="center" width="100%">
                {(auth.roles === "admin") ? <Button onClick={() => setEditReportModal(true)} mr="27%" colorScheme="blue">{currentType ? 'Edit Report' : 'Add Report'}</Button> : null}
                    <Center>
                        <Select placeholder="Select option" width="100%" onChange={e => getReportKey(e.target.value)} size="lg">
                            {
                                options.map((option, index) => {
                                    return (
                                        <option key={index} value={option.queryName}>{option.reportName}</option>
                                    )
                                })
                            }
                        </Select>
                    </Center>
                </Flex>
                {currentType && <ReportTable key={currentType} currentType={currentType} />}
            </Flex>

            <EditReportModal open={editReportModal} close={() => setEditReportModal(false)} report={currentType} />
        </Box>
    )

}

export default DynamicReports;