import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    Heading,
    Spinner,
    Select,
    Center,
    Button,
    Text,
} from '@chakra-ui/react';
import { useApp } from '../context';
import JobForm from '../components/AddUpdateJobs/JobForm';
import JobTable from '../components/AddUpdateJobs/JobTable';

const AddUpdateJobs = () => {
    const app = useApp();
    const [jobData, setJobData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [showJobForm, setShowJobForm] = useState(false);
    const [selectedCompanyObject, setSelectedCompanyObject] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const isMounted = useRef(true);

    const getJobs = async () => {
        try {
            setLoading(true);
            const response = await app.helloFambase(
                'GET',
                'dashboard/companies-jobs'
            );
            if (response.success && isMounted.current) {
                setJobData(response.data);
            }
        } catch (error) {
            window.alert('Error getting jobs:', error);
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        isMounted.current = true;
        getJobs();

        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleAddJobClick = () => {
        setShowJobForm(true);
        setSelectedJob(null);
    };
    const handleCompanySelect = (event) => {
        const selectedCompanyName = event.target.value;
        setSelectedCompany(selectedCompanyName);

        if (selectedCompanyName === 'All') {
            setSelectedCompanyObject(jobData);
        } else {
            const companyObject = jobData.find(
                option => option.companyName === selectedCompanyName
            );
            setSelectedCompanyObject(companyObject);
        }

        setShowJobForm(false);
    };
    const handleEditJob = (job, company) => {
        setShowJobForm(true);
        setSelectedCompanyObject(company);
        setSelectedJob(job);
    };
    const navigateBack = () => {
        setShowJobForm(false);
        setSelectedJob(null);
    };

    return (
        <Box
            height="100%"
            w="100%"
            display="flex"
            flexDirection="column"
            overflowY="scroll"
            css={{
                '&::-webkit-scrollbar': {
                    width: '0px',
                },
            }}
        >
            <Heading textAlign="center">Add/Update Jobs</Heading>
            <Text fontSize="2xl" textAlign="center" pt="2rem" mb="2rem">
                Select a Company to Add a Job
            </Text>

            {loading ? (
                <Flex justify="center" mt={10}>
                    <Spinner />
                </Flex>
            ) : (
                <>
                    <Center>
                        <Flex
                            alignItems="center"
                            w="100%"
                            gridGap="2rem"
                            justifyContent="center"
                        >
                            <Select
                                placeholder="Select a Company"
                                width="60%"
                                size="lg"
                                onChange={handleCompanySelect}
                                value={selectedCompany || ''}
                            >
                                <option value="All">All</option>
                                {jobData.map(option => (
                                    <option
                                        key={option.companyName}
                                        value={option.companyName}
                                    >
                                        {option.companyName}
                                    </option>
                                ))}
                            </Select>

                            {!showJobForm &&
                                selectedCompany &&
                                selectedCompany !== 'All' && (
                                    <Button
                                        colorScheme="green"
                                        onClick={handleAddJobClick}
                                    >
                                        Add Job
                                    </Button>
                                )}
                        </Flex>
                    </Center>
                    {(selectedCompany === 'All' ||
                        (selectedCompany && selectedCompany !== 'All')) &&
                        !showJobForm && (
                            <JobTable
                                companies={selectedCompanyObject}
                                handleEditJob={handleEditJob}
                            />
                        )}
                    {showJobForm && (
                        <JobForm
                            selectedCompany={selectedCompanyObject}
                            navigateBack={navigateBack}
                            selectedJob={selectedJob}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default AddUpdateJobs;
