import React, { useEffect, useState } from 'react';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Center,
    CircularProgress,
    Table,
    Thead,
    Tr,
    Td,
    Th,
    Tbody,
    TableCaption
} from '@chakra-ui/react';

import { SinglePagePagination } from '../ui-molecules';
import { useApp } from '../../context';

const ViewMembersModal = ({open, close, mailingListData}) => {
    const app = useApp();

    const [dataLoading, setDataLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [offset, setOffset] = useState(0);
    const [members, setMembers] = useState([]);
    const [nextList, setNextList] = useState(null);
    const [currentMembers, setCurrentMembers] = useState([]);

    const getMembers = async () => {
        setDataLoading(true);

        const data = {
            limit: 20,
            nextList: nextList,
            mailingList: mailingListData.address
        }

        const response = await app.helloFambase(
            'POST',
            'dashboard/list-mailing-list-members',
            data
        )

        setDataLoading(false);
        console.log(response);

        if(response.success) {
            setMembers([...members, ...response.data.items]);
            setNextList(response.data.paging.next);
        }
    }

    useEffect(() => {
        if(pageNumber * 20 >= members.length) {
            getMembers();
        }
    }, [offset])

    useEffect(() => {
        setCurrentMembers(members.slice(20 * (pageNumber - 1), 20 * pageNumber))
    }, [members, pageNumber])

    return(
        <Modal size="5xl" isOpen={open} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Members List of - {mailingListData.address}</ModalHeader>
                <ModalCloseButton />

                <ModalBody pb="10">
                    { dataLoading ?
                        <Center h="50%"><CircularProgress isIndeterminate color="red.400" /></Center>
                        :
                        <Table mt="10" mb="10">
                            <Thead border="1px">
                                <Tr border="1px">
                                    <Th textAlign="center" border="1px">Email</Th>
                                    <Th textAlign="center" border="1px">Name</Th>
                                    <Th textAlign="center" border="1px">Subscribed</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    currentMembers.map((member, index) => {
                                        return(
                                            <Tr border="1px" key={index}>
                                                <Td textAlign="center" border="1px">{member.address}</Td>
                                                <Td textAlign="center" border="1px">{member.name}</Td>
                                                <Td textAlign="center" border="1px">{member.subscribed ? 'Yes' : 'No'}</Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                            {members.length === 0 && (
                                <TableCaption>No Members found</TableCaption>
                            )}
                        </Table>
                    }

                    <SinglePagePagination 
                        pageNumber={pageNumber}
                        onLeftClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1);
                            }
                        }}
                        onRightClick={() => {
                            if (members.length > 0) {
                                setOffset(offset + 20);
                                setPageNumber(pageNumber + 1);
                            }
                        }}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ViewMembersModal;