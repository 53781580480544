import React from 'react';

import PropTypes from 'prop-types';

import Context from './context';
import useProvideAuth from './provideAuth';

const Provider = ({ children }) => {
  const auth = useProvideAuth();
  return <Context.Provider value={auth}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.any,
};

export default Provider;
