import React, { useState } from 'react';

import {
    Box,
    Text,
    Button,
    Input,
    Flex,
    Spinner,
    InputGroup,
    InputLeftAddon
} from '@chakra-ui/react';

import { environment } from '../../config';
import { useApp } from '../../context'

const Create = () => {
    const [userId, setUserId] = useState(0);
    const [planId, setPlanId] = useState(0);
    const [segmentName, setSegmentName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, showError] = useState(false);
    const [success, setSuccess] = useState(false);

    const app = useApp();

    const userIdChangeHandler = (e) => {
        setUserId(e.target.value);
    }

    const planIdChangeHandler = (e) => {
        setPlanId(e.target.value);
    }

    const segmentChangeHandler = (e) => {
        setSegmentName(e.target.value);
    }

    const submitHandler = async () => {
        showError(false);
        setSuccess(false);
        setLoading(true);

        const data = {
            userId: userId,
            planId: planId,
            segmentName: segmentName.replace(/\s/g, '_')
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/create-mailing-list',
            data
        )
        console.log(response);
        setLoading(false);

        if(response.success){
            setSuccess(true);
        }
        else showError(true);
    }

    return(
        <Box mt="20" ml="20" mr="40" height="100%">
            <Flex>
                <Flex direction="column" mr="10">
                    <Text size="md" mb="8px">Enter user id or username of the creator</Text>
                    <Input onChange={userIdChangeHandler} placeholder="User Id / Username" size="md" />
                </Flex>
                <Flex direction="column">
                    <Text size="md" mb="8px">Enter plan id of the creator or 0 for free plan</Text>
                    <Input onChange={planIdChangeHandler} placeholder="Plan Id" size="md" />
                </Flex>
            </Flex>

            <Flex mt="10" direction="column">
                <Text size="md" mb="8px">Enter name (without spaces) if creating general list other than that of creators (Ignore this field if filled above options and vice versa)</Text>
                <InputGroup>
                    {/* <InputLeftAddon>promo_</InputLeftAddon> */}
                    <Input onChange={segmentChangeHandler} placeholder="List name (eg. all, all_creators)" size="md" />
                </InputGroup>
            </Flex>

            <Text mt="10" mb="8px">Mailing List - {segmentName === "" ? userId+"_"+planId : segmentName}_mailing_list@mg1.{environment == "production" ? 'mail.terriblytinytales.com' : 'tdingapp.com'}</Text>

            <Button size="md" mt="10" colorScheme="red" onClick={submitHandler}>
                {loading ? <Spinner /> : "Submit"}
            </Button>

            { error && <Text size="md" mb="8px" mt="10" color="red">Error Occurred</Text> }
            { success && <Text size="md" mb="8px" mt="10" color="green">Mailing List successfully created</Text> }

        </Box>
    )
}

export default Create;