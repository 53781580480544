import React from 'react';

import { Flex, Box, Center, Text } from '@chakra-ui/react';

const Plain = ({ children }) => {
  return (
    <Center w="100%" minH="100vh">
      <Center flex="1">{children}</Center>
    </Center>
  );
};

export default Plain;
