import React, { useRef, useState, useEffect } from 'react';

import {
    Center, Input, Spinner, Box, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, Text, Flex, Select
} from '@chakra-ui/react';

import { useApp, useUtils } from '../../context'
import Papa from 'papaparse';
import papaparse from 'papaparse';
import ErrorDialog from '../ErrorMessage/ErrorDialog'

const ImportSubscriberModal = ({ isOpen, onOpen, onClose, userId }) => {
    const utils = useUtils();

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [importedResult, setImportedResult] = useState([])
    const [errorMessage, setErrorMessage] = useState();
    const [plans, setPlans] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(-1);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [expireBy, setExpireBy] = useState();

    const [singleUser, setSingleUser] = useState({
        name: '',
        email: '',
    });

    const inputRef = useRef();
    const modalRef = useRef();
    const inputCoverRef = useRef();

    const app = useApp();

    const getPlans = async () => {
        let response = await app.helloFambase(
            'POST',
            'dashboard/planDetails',
            {userId: userId}
        )
        console.log(response);
        if(response.success){
            let temp = [];
            let tempPeriods = {};
            let tempObj = {};

            response.data.map((plan) => {
                if(plan.amount === 0){ 
                    plan.id = 0;
                    plan.pid = 0;
                }

                tempPeriods[plan.id] = tempPeriods[plan.id] ? tempPeriods[plan.id] : [];
                tempPeriods[plan.id].push(plan);

                if(!tempObj[plan.id]){
                    tempObj[plan.id] = {
                        id: plan.id,
                        name: plan.name
                    }
                    temp.push({
                        id: plan.id,
                        name: plan.name
                    })   
                }

                return 1;
            })
         
            setPlans(temp);
            setPeriods(tempPeriods);
        }
    }

    useEffect(() => {
        getPlans();

        //set expireBy to todays date + 1 year
        let today = new Date();
        today.setFullYear(today.getFullYear() + 1);
        let date = today.toISOString().split('T')[0];
        setExpireBy(date + " 23:59:59");
    }, [])

    const planSelectHandler = (e) => {
        if(e.target.value == 0){
            setSelectedPeriod(0);
        }
        setSelectedPlan(e.target.value);
        console.log("selected plan ", periods[e.target.value][0].pid)
        setSelectedPeriod(periods[e.target.value][0].pid);
    }

    const periodSelectHandler = (e) => {
        setSelectedPeriod(e.target.value);
        console.log("selected period ", e.target.value)
    }

    const expireBySelectHandler = (e) => {
        let date = e.target.value + " 23:59:59";
        setExpireBy(date);
    }

    const validate = () => {
        if(selectedPlan === -1) {
            alert("Select Plan!!");
            return false;
        }

        if(selectedPlan == 0){
            if(!inputRef.current.files[0] && !singleUser.name && !singleUser.email) {
                alert("Upload File or Enter User!!");
                return false;
            }

            return true;
        }

        else{
            if(selectedPeriod === 0) {
                alert("Select Period!!");
                return false;
            }

            if(expireBy === ''){
                alert("Enter Expire By!!");
                return false;
            }

            if(!inputRef.current.files[0] && !singleUser.name && !singleUser.email) {
                alert("Upload File or Enter User!!");
                return false;
            }
        }

        return true;
    }

    const onChangeInput = async (e) => {
        if(!validate()) return;

        console.log("file ", inputRef.current.files[0]);
        e.preventDefault();
        setIsError(false);
        try {
            const file = inputRef.current.files[0];
            if (file) {
                setLoading(true);
                Papa.parse(file, {
                    complete: async (data) => {
                        if (
                            data &&
                            data.data &&
                            Array.isArray(data.data) &&
                            data.data.length > 1
                        ) {
                            console.log(data.data)

                            if(
                                (data.data[0][0].toLowerCase() === "name") 
                                && (data.data[0][1].toLowerCase() === "email") 
                                && ((data.data[0][2].toLowerCase() === "mobile number")
                                || (data.data[0][2].toLowerCase() === "phone number")
                                || (data.data[0][2].toLowerCase() === "number"))
                            ){
                                let resultData = await utils.onImport(data.data, file.name, userId, selectedPlan, selectedPeriod, expireBy);
                                console.log("RESULT ", resultData)
                                setImportedResult(resultData)
                                setIsUploaded(true)
                            }

                            else{
                                setIsError(true);
                                setErrorMessage('Incorrect File Format. Please upload as per the sample format, column names and order must be the same. -> [Name, Email, Mobile Number]');
                            }
                        } else {
                            setIsError(true);
                            setErrorMessage('Error message for incorrect formatting of the csv file');
                        }
                        setLoading(false);
                        if (inputRef && inputRef.current)
                            inputRef.current.value = '';
                    },
                    error: () => {
                        setIsError(true);
                        setErrorMessage('Error message for incorrect formatting of the csv file');
                        setLoading(false);
                        if (inputRef && inputRef.current)
                            inputRef.current.value = '';
                    },
                });
            }

            else if(singleUser.name && singleUser.email){
                setLoading(true);
                console.log("single user ", singleUser)

                let resultData = await utils.onImport([[],[singleUser.name, singleUser.email, '']], "single_user.csv", userId, selectedPlan, selectedPeriod, expireBy);
                console.log("RESULT ", resultData)
                setImportedResult(resultData)
                setIsUploaded(true);

                setLoading(false);
            }
        } catch (err) {
            console.log("in error ", err)

            setIsError(true);
            setErrorMessage('Error message for incorrect formatting of the csv file');
            setLoading(false);
        }
    }

    const downloadData = async () => {
        let csvString = papaparse.unparse(importedResult.data);
        csvString = `data:text/csv;charset=utf-8,${csvString}`;
        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvString));
        const today = new Date();
        link.setAttribute(
            'download',
            `${today.toLocaleDateString()}_import_result.csv`,
        );
        link.click();
    }

    const downloadSample = async () => {
        const downloadData = [
            [
                'Name',
                'Email',
                'Mobile Number'
            ],
        ];

        let csvString = papaparse.unparse(downloadData);
        csvString = `data:text/csv;charset=utf-8,${csvString}`;
        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvString));
        const today = new Date();
        link.setAttribute(
            'download',
            `${today.toLocaleDateString()}_sample_for_import.csv`,
        );
        link.click();
    }

    const clearError = () => {
        setIsError(false);
    }

    return (
        <Modal isOpen={isOpen} onClose={() => { setIsUploaded(false); onClose() }} size="4xl" ref={modalRef}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Import Subscribers</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        {
                            isError && <ErrorDialog errorMessage={errorMessage} isOpen={isOpen} onClose={() => {onClose(); clearError(); }} onOpen={onOpen} />
                        }
                        <Center mt="4">
                            <Flex direction="column">
                                <Box>
                                    <Text fontSize="20px">
                                        1. The order of the CSV columns should be: Name, Email, Mobile
                                    </Text>

                                    <Flex align={'center'} justify={'space-between'}>
                                        <Button
                                            size="md"
                                            mt="4"
                                            colorScheme="linkedin"
                                            onClick={() => {
                                                downloadSample()
                                            }}
                                        >
                                            Download the sample CSV
                                        </Button>

                                        <Input
                                            placeholder={"Your file ..."}
                                            style={{ cursor: "pointer", textAlign: "center" }}
                                            onClick={() => inputRef.current.click()}
                                            value={"Import Subscribers"}
                                            type="button"
                                            mt="5"
                                            w="50%"
                                            ref={inputCoverRef}
                                            disabled={singleUser.name || singleUser.email}
                                        />
                                    </Flex>

                                    <Text mt={5} fontSize="20px">
                                        OR
                                    </Text>

                                    <Flex mt={5} align={'center'}>
                                        <Text mr="10" fontSize="20px">
                                            Name
                                        </Text>

                                        <Input
                                            type="text"
                                            placeholder="Name"
                                            onChange={(e) => {
                                                setSingleUser({ ...singleUser, name: e.target.value })
                                                }
                                            }
                                            value={singleUser.name}
                                            disabled={inputRef.current?.files[0]}
                                        />
                                    </Flex>

                                    <Flex mt={5} align={'center'}>
                                        <Text mr="10" fontSize="20px">
                                            Email
                                        </Text>

                                        <Input
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) => {
                                                setSingleUser({ ...singleUser, email: e.target.value })
                                                }
                                            }
                                            value={singleUser.email}
                                            disabled={inputRef.current?.files[0]}
                                        />
                                    </Flex>

                                    <Text mt={10} fontSize="20px">
                                        2. Email is mandatory
                                    </Text>
                                </Box>

                                <Box mt={5}>
                                    <Text fontSize="20px">
                                       3. Select the plan to import subscribers into
                                    </Text>
                                    <Select mt={2} placeholder="Select Plan" onChange={planSelectHandler} required>
                                        {plans.length && plans.map((plan) => {
                                            return(
                                                <option key={plan.id} value={plan.id}>{plan.name}</option>
                                            )
                                        })}
                                    </Select>
                                    {selectedPlan == 0 && 
                                        <Text mt="2" fontSize="12px">
                                            Free Plan
                                        </Text>
                                    }
                                </Box>
                                
                                {/* {selectedPlan != 0 && selectedPlan !== -1 && 
                                    <Box mt={5}>
                                        <Text fontSize="20px">
                                        4. Select Plan Period
                                        </Text>
                                        <Select mt={2} placeholder="Select Period" onChange={periodSelectHandler}>
                                            {periods[selectedPlan] && periods[selectedPlan].map((period) => {
                                                return(
                                                    <option key={period.pid} value={period.pid}>{period.period}</option>
                                                )
                                            })}
                                        </Select>
                                    </Box>
                                }

                                {selectedPlan != 0 && selectedPlan !== -1 &&
                                    <Box mt={5}>
                                        <Text fontSize="20px">
                                            5. Enter Expire By 
                                        </Text>
                                        <Input mt={2} type="date" onChange={expireBySelectHandler} />
                                    </Box>
                                } */}

                                {loading ? <Center><Spinner /></Center> :
                                    <Center>
                                        <Button
                                            size="md"
                                            mt="4"
                                            ml="5"
                                            colorScheme="linkedin"
                                            onClick={onChangeInput}
                                        >
                                            Submit
                                        </Button>
                                    </Center>
                                }

                                {isUploaded &&
                                    <Box>
                                        <Box>
                                            <Center>
                                                <Flex direction="column">

                                                    <Text fontSize="20px" color="green">Imported successfully: {importedResult.created}</Text>
                                                    <Text fontSize="20px" color="red">Import failed: {importedResult.failed}</Text>
                                                </Flex>
                                            </Center>

                                        </Box>

                                        <Center>
                                            <Button
                                                size="md"
                                                mt="4"
                                                colorScheme="green"
                                                onClick={() => {
                                                    downloadData()
                                                }}
                                            >
                                                View the Imported Result
                                        </Button>
                                        </Center>
                                    </Box>
                                }

                            </Flex>
                            <input
                                type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                id={`bannerUpload_${userId}`}
                                accept=".csv"
                                onChange={() => {inputCoverRef.current.value="File Added"}}
                            />

                        </Center>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={() => { setIsUploaded(false); onClose() }}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}

export default ImportSubscriberModal;
