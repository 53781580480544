import React from 'react';

import { Button } from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';

const SidebarItem = ({ item, isActive = false, handleClick }) => {
  return (
    <Button
      my={2}
      isActive={isActive}
      colorScheme="red"
      variant="ghost"
      justifyContent="flex-start"
      rightIcon={isActive ? <ArrowForwardIcon /> : null}
      onClick={handleClick}
      justifyContent="space-between"
    >
      {item}
    </Button>
  );
};

export default SidebarItem;
