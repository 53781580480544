import React, { useEffect, useState } from 'react';
import { 
    Box, 
    Heading, 
    Spinner,
    Flex, 
    Table,
    Button,
    Tr,
    Td,
    Th
} from '@chakra-ui/react';

import { useApp } from '../context';
import papa from 'papaparse';

const FambaseExportData = () => {
    const app = useApp();

    const [loading, setLoading] = useState(true);
    const [creatorData, setCreatorData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try{
            const data = await app.helloFambase(
                'GET',
                'dashboard/all-fambase-creators-data'
            )

            if(data.success){
                setCreatorData(data.data);
                setLoading(false);
            }

        } catch(err){
            console.log(err);
            setLoading(false);
        }
    }

    const exportSubscribersHandler = async (index) => {
        try{
            //export to csv
            const csv = papa.unparse([
                ...creatorData[index].subscribers
            ])

            const element = document.createElement("a");
            const file = new Blob([csv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = creatorData[index].creator + "-subscribers.csv";
            document.body.appendChild(element);

            element.click();

        } catch(err){
            console.log(err);
        }
    }

    const exportContentHandler = async (index) => {
        try{
            //export to csv
            const csv = papa.unparse([
                ...creatorData[index].content
            ])

            const element = document.createElement("a");
            const file = new Blob([csv], {type: 'text/csv'});
            element.href = URL.createObjectURL(file);
            element.download = creatorData[index].creator + "-content.csv";
            document.body.appendChild(element);

            element.click();

        } catch(err){
            console.log(err);
        }
    }

    return(
        <Box h="100%">
            <Heading textAlign={'center'}>Export Data</Heading>

            {loading ?
                <Flex justify="center" align="center" h="100%">
                    <Spinner size="xl" />
                </Flex>
            :
                <Box w="65%" h="80%" align="center" m="auto" mt="10" overflow="scroll" css={{
                    '&::-webkit-scrollbar': {
                        width: '0px',
                    },
                }}>
                    <Table variant="striped" colorScheme="teal">
                        <thead>
                            <Tr>
                                <Th textAlign="center" border="1px">Creator</Th>
                                <Th textAlign="center" border="1px">Subscribers</Th>
                                <Th textAlign="center" border="1px">Content</Th>
                            </Tr>
                        </thead>
                        <tbody>
                            {creatorData.map((creator, index) => {
                                return (
                                    <Tr textAlign="center" key={index}>
                                        <Td textAlign="center" border="1px">{creator.creator}</Td>
                                        <Td width="40" textAlign="center" border="1px"><Button onClick={exportSubscribersHandler.bind('this', index)} colorScheme="green">Export</Button></Td>
                                        <Td width="40" textAlign="center" border="1px"><Button onClick={exportContentHandler.bind('this', index)} colorScheme="yellow">Export</Button></Td>
                                    </Tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Box>
            }

        </Box>
    )
}

export default FambaseExportData;