import React, { useState } from 'react';

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';

import { SearchIcon } from '@chakra-ui/icons';

// Add Search loading - Replace Search icon with Loading animation
const Search = ({ handleSearch, placeholder }) => {
  const [searchText, setSearchText] = useState();

  return (
    <InputGroup my={5} maxW="500px" mx="auto">
      <Input
        placeholder={placeholder}
        variant="filled"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleSearch(searchText);
          }
        }}
        onChange={e => setSearchText(e.target.value)}
      />
      <InputRightElement
        pointerEvents="none"
        children={<SearchIcon color="gray.300" />}
        variant="filled"
      />
    </InputGroup>
  );
};

export default Search;
