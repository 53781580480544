import React, { useState} from 'react';

import {
    Center, Box, Select, Flex

} from '@chakra-ui/react';

import Search from '../components/MailingLists/Search';
import Create from '../components/MailingLists/Create';
import Subscribe from '../components/MailingLists/Subscribe';
import RuleSet from '../components/MailingLists/RuleSet';
import AddTemplate from '../components/MailingLists/AddTemplate';

const preData = {
    templateName: "",
    template: "",
    subject: "",
    fromName: "",
    fromMail: "",
    mailingListName: "",
}

const MailingLists = () => {
    const [option, setOption] = useState(null);

    const changeHandler = (e) => {
        setOption(e.target.value);
    }

    return(
        <Box
            h="100%"
        >
            <Flex direction="column" h="100%">
                <Center>
                    <Select placeholder="Select option" width="40%" size="lg" onChange={changeHandler}>
                        <option value="search">Search Mailing List</option>        
                        <option value="create">Create Mailing List</option>        
                        <option value="subscribe">Subscribe to Mailing List</option>        
                        <option value="rule-set">Add Mail Rule Set</option>        
                        {/* <option value="add-template">Attach Template to Mailing List</option> */}
                    </Select>
                </Center>
                {option === "search" && <Search key={option} currentType={option} />}
                {option === "create" && <Create key={option} currentType={option} />}
                {option === "subscribe" && <Subscribe key={option} currentType={option} />}
                {option === "rule-set" && <RuleSet key={option} currentType={option} />}
                {/* {option === "add-template" && <AddTemplate key={option} currentType={option} preData={preData}/>} */}
            </Flex>
        </Box>
    )
}

export default MailingLists;