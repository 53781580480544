import React, { useState } from 'react';

import {
    Box,
    Flex,
    Input,
    Text,
    Button,
    Spinner,
    InputGroup,
    InputRightAddon,
    InputLeftAddon,
} from '@chakra-ui/react';

import { useApp } from '../../context';
import { environment } from '../../config';
import BatchUploadModal from './BatchUploadModal';

const Subscribe = () => {
    const [segmentName, setSegmentName] = useState("");
    const [subscriberUsername, setSubscriberUsername] = useState("");
    const [email, setEmail] = useState("");
    const [JSONdata, setJSONData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, showError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openBatchUploadModal, setOpenBatchUploadModal] = useState(false);

    const app = useApp();

    const segmentChangeHandler = (e) => {
        setSegmentName(e.target.value);
    }

    const submitHandler = async () => {
        showError(false);
        setSuccess(false);
        setLoading(true);

        const data = {
            segmentName: segmentName,
            email: email,
            name: subscriberUsername,
            externalJSONData: JSONdata
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/subscribe-mailing-list',
            data
        )
        console.log(response);
        setLoading(false);

        if(response.success){
            setSuccess(true);
        }
        else showError(true);
    }

    return(
        <>
            <Box mt="10" ml="20" mr="40" height="100%">
                {/* <Flex>
                    <Flex direction="column" mr="10">
                        <Text size="md" mb="8px">Enter user id or username of the creator</Text>
                        <Input onChange={userIdChangeHandler} placeholder="User Id / Username" size="md" />
                    </Flex>
                    <Flex direction="column">
                        <Text size="md" mb="8px">Enter plan id of the creator or 0 for free plan</Text>
                        <Input onChange={planIdChangeHandler} placeholder="Plan Id" size="md" />
                    </Flex>
                </Flex> */}

                <Flex mt="5" direction="column">
                    <Text size="md" mb="8px">Enter name of mailing list (Use Import Subscribers (In Search Users) to add members to creators mailing list)</Text>
                    <InputGroup>
                        {/* <InputLeftAddon></InputLeftAddon> */}
                        <Input onChange={segmentChangeHandler} placeholder="List name (eg. all, all_creators)" size="md" />
                        <InputRightAddon>_mailing_list@mg1.{environment === "production" ? 'mail.terriblytinytales.com' : 'tdingapp.com'}</InputRightAddon>
                    </InputGroup>
                </Flex>

                <Flex mt="10" align="end">
                    <Flex direction="column" mr="10">
                        <Text size="md" mb="8px">Subscriber Name</Text>
                        <Input onChange={(e) => setSubscriberUsername(e.target.value)} placeholder="username" size="md" />
                    </Flex>

                    <Flex direction="column">
                        <Text size="md" mb="8px">Subscriber Email</Text>
                        <Input onChange={(e) => setEmail(e.target.value)} placeholder="email" size="md" />
                    </Flex>

                    <Flex align="center" ml="10">
                        <Button ml="15" colorScheme="teal" disabled={segmentName === "" ? true : false} onClick={() => setOpenBatchUploadModal(true)}>Batch Upload</Button>
                    </Flex>
                </Flex>

                <Flex mt="10" direction="column">
                    <Text size="md" mb="8px">External JSON Data</Text>
                    <Input onChange={(e) => setJSONData(e.target.value)} placeholder="" size="md" />
                </Flex>

                <Button size="md" mt="10" colorScheme="red" onClick={submitHandler}>
                    {loading ? <Spinner /> : "Submit"}
                </Button>

                { error && <Text size="md" mb="8px" mt="5" color="red">Error Occurred</Text> } 
                { success && <Text size="md" mb="8px" mt="5" color="green">Subscribed Successfully</Text> } 
            </Box>

            <BatchUploadModal open={openBatchUploadModal} close={() => setOpenBatchUploadModal(false)} name={`${segmentName}_mailing_list@mg1.${environment === "production" ? 'mail.terriblytinytales.com' : 'tdingapp.com'}`} segmentName={segmentName} />
        </>
    )
}

export default Subscribe;