import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, useDisclosure, Divider, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons'

import { Sidebar } from '../components/layout';

const Dashboard = ({ children }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  const closeMenu = () => {
    if(isOpen) onToggle();
  }

  return (
    <Box w="100vw" h="100vh">
      <Flex h="100%" w="100%" overflow="hidden">
        {
          isOpen ? (
            <Sidebar onToggle={onToggle} closeMenu={closeMenu}  />
          ) : (
            <Box m={3} >
              <IconButton aria-label='Search database' icon={<HamburgerIcon />} onClick={onToggle} />
            </Box>
          )
        }
        <Box p={10} w="100%" h="100vh" overflow="hidden">
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

Dashboard.propTypes = {
  children: PropTypes.any,
};

export default Dashboard;
