import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import context from './context';
import { useApp } from "../index";

const Provider = ({ children }) => {
    const app = useApp();

    const getSignedUrl = async (
        fileDetails = {
            localFileName: 'file_name',
            contentType: 'image',
            isAsset: false
        },
    ) => {
        try {
            let signedUrl = await app.helloFambase(
                'POST',
                'content/signed-url',
                fileDetails,
            );
            return signedUrl
        } catch (err) {
            console.log('getSingedUrl err', err);
        }
    };

    const renameFile = (originalFile, userId) => {
        const unixTime = Math.floor(Date.now() / 1000);
        return new File(
            [originalFile],
            `${userId}_${unixTime}`,
            {
                type: originalFile.type,
                lastModified: originalFile.lastModified,
            },
        );
    };

    const uploadToS3 = async (
        file,
        userId,
        callback,
        cancelToken,
    ) => {
        try {
            let uploadRes = null;
            let contentType = 'image';
            let finalFile = renameFile(file, userId);

            let indexTilFileExt = finalFile.name.lastIndexOf('.');

            uploadRes = await getSignedUrl({
                localFileName: finalFile.name.substr(
                    0,
                    indexTilFileExt == -1 ? finalFile.name.length : indexTilFileExt,
                ),
                contentType,
            });

            const config = {
                onUploadProgress(progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    );
                    if (callback) callback(percentCompleted);
                },
                headers: {
                    'Content-Type': finalFile.type,
                },
            };
            if (cancelToken) config.cancelToken = cancelToken;
            await axios.put(uploadRes.data.signedURL, finalFile, config);
            return uploadRes.data.publicURL
        } catch (err) {
            console.log('uploadToS3 err', err);
        }
    };

    const uploadToS3v2 = async (
        file,
        fileName,
        contentType,
        callback = null,
        cancelToken = null,
    ) => {
        try{
            let uploadRes = null;

            uploadRes = await getSignedUrl({
                localFileName: fileName,
                contentType,
                isAsset: true
            })

            console.log('upload res - ', uploadRes);

            const config = {
                onUploadProgress(progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    );
                    if (callback) callback(percentCompleted);
                },
                header: {
                    'Content-Type': file.type,
                },
            }

            if(cancelToken) config.cancelToken = cancelToken;

            await axios.put(uploadRes.data.signedURL, file, config);

            return uploadRes.data.publicURL;

        } catch(err){
            console.log('err in uploadToS3v2 - ',err);
        }
    }


    const addSubscribers = async (
        data = [], userId, planId, periodId, expireBy
    ) => {
        let body = {
            userId: userId,
            subscriberData: data,
            planId: planId,
            periodId: periodId,
            expireBy: expireBy
        }
        const response = await app.helloFambase(
            'POST',
            'dashboard/addSubscriber',
            body,
        );
        console.log("response from import ", response)
        return response && response.data ? response.data : {};
    };

    const onImport = async (
        data = [],
        filename = 'subscribers_import_report.csv',
        userId,
        selectedPlan,
        selectedPeriod,
        expireBy
    ) => {
        try {
            const promises = [];
            let queue = [];
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let status = {};
            let duplicates = [];
            let created = 0;
            let failed = 0;

            //helper function
            const validArray = (arr = []) => {
                for (let i = 0; i < arr.length; i += 1) {
                    if (arr[i] && arr[i].toString().trim() !== '') {
                        return true;
                    }
                }
                return false;
            };

            // filter the duplicate entries before sending to the backend
            const uniqueEmails = (arr = []) => {
                let seen = {};
                let uniqueArr = [];
                uniqueArr = arr.filter(function(item) {
                    if(seen.hasOwnProperty(item)){
                        duplicates.push(item);
                        return false;
                    }
                    return (seen[item] = true);
                });

                return uniqueArr;
            }

            data = uniqueEmails(data);
            
            for (
                let subscriberIndex = 1;
                subscriberIndex < data.length;
                subscriberIndex += 1
            ) {
                const currentSubscriber = data[subscriberIndex];

                //if we have valid row and its email
                if (currentSubscriber && currentSubscriber[1]) {
                    currentSubscriber[1] = currentSubscriber[1]
                        .toString()
                        .trim();
                }

                if (
                    currentSubscriber &&
                    Array.isArray(currentSubscriber) &&
                    regex.test(currentSubscriber[1])
                ) {
                    queue.push({
                        name: currentSubscriber[0] ? currentSubscriber[0] : '',
                        email: currentSubscriber[1] ? currentSubscriber[1] : '',
                        mobile: currentSubscriber[2] ? currentSubscriber[2] : '',
                    });
                } else if (currentSubscriber) {
                    if (validArray(currentSubscriber)) {
                        status[currentSubscriber[1]] = {
                            status: 'Invalid Data',
                            id: 0,
                        };
                        failed += 1;
                    }
                }

                if (
                    queue.length === 100 ||
                    subscriberIndex === data.length - 1
                ) {
                    promises.push(
                        addSubscribers(queue, userId, selectedPlan, selectedPeriod, expireBy),
                    );
                    queue = [];
                }
            }

            const syncAddSubscribers = async (currentIndex) => {
                try {
                    let p = Math.round(
                        ((currentIndex + 1) * 100) / promises.length,
                    );
                    if (p < 0) p = 0;
                    else if (p > 100) p = 100;
                    if (!promises[currentIndex]) return;
                    const response = await promises[currentIndex];
                    if (
                        response &&
                        response.importSummary &&
                        typeof response.importSummary === 'object'
                    ) {
                        status = { ...status, ...response.importSummary };
                    }
                    if (response && response.importCountSummary) {
                        created += response.importCountSummary.added || 0;
                        failed += response.importCountSummary.failed || 0;
                    }
                } catch (err) {
                    // do nothing
                }
                await syncAddSubscribers(currentIndex + 1);
            };

            if (promises.length > 0) await syncAddSubscribers(0);

            const downloadData = [];
            for (
                let dataIndex = 0;
                dataIndex < data.length;
                dataIndex += 1
            ) {
                if (data[dataIndex] && Array.isArray(data[dataIndex])) {
                    const newData = [...data[dataIndex]];
                    let s = '';
                    if (dataIndex === 0) {
                        s = 'Status';
                    } else if (
                        status[newData[1]] &&
                        status[newData[1]].status
                    ) {
                        s = status[newData[1]].status;
                    }
                    newData[newData.length] = s;
                    downloadData.push(newData);
                } else {
                    downloadData.push(data[dataIndex]);
                }
            }

            // push the duplicate entries in the end 
            for(let i=0; i<duplicates.length; i++){
                let dupData = [...duplicates[i]];
                dupData[dupData.length] = "Duplicate Entry";
                downloadData.push(dupData);
            }

            failed += duplicates.length;

            return {
                data: downloadData,
                failed: failed,
                created: created,
            }
            // let csvString = papaparse.unparse(downloadData);
            // myAlert.showAlert({
            //     title: 'Subscriber list imported',
            //     message: `${created} records uploaded where ${failed} records failed to upload. Please download report file for details`,
            //     buttons: [
            //         {
            //             text: 'Cancel',
            //             onClick: () => { },
            //             cancel: true,
            //         },
            //         {
            //             text: 'Download',
            //             onClick: () => {
            //                 csvString = `data:text/csv;charset=utf-8,${csvString}`;
            //                 const link = document.createElement('a');
            //                 link.setAttribute('href', encodeURI(csvString));
            //                 link.setAttribute('download', filename);
            //                 link.click();
            //             },
            //         },
            //     ],
            // });
        } catch (err) {
            console.log('Failed to import subscribers, please try again!')
            // toastContext.showToast(
            //     'Failed to import subscribers, please try again!',
            // );
        }
    };

    const addMembers = async (members, mailingList) => {
        const data = {
            members: members,
            mailingList: 'promo_'+mailingList,
            batchUpload: true
        }

        const response = await app.helloFambase(
            'POST',
            'dashboard/subscribe-mailing-list',
            data
        )

        if(response.success) return true;
        else return false;
    }   

    const onMembersImport = async (data, filename="members_import_report.csv", mailingList) => {
        try{
            let duplicates = [];
            let queue = [];
            let status = {};
            let added = 0;
            let failed = 0;
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            const uniqueMembers = (arr = []) => {
                let seen = {};
                let uniqueArr = [];
                uniqueArr = arr.filter(function(item) {
                    if(seen.hasOwnProperty(item)){
                        duplicates.push(item);
                        return false;
                    }
                    return (seen[item] = true);
                });

                return uniqueArr;
            }

            const validArray = (arr = []) => {
                for (let i = 0; i < arr.length; i += 1) {
                    if (arr[i] && arr[i].toString().trim() !== '') {
                        return true;
                    }
                }
                return false;
            };

            data = uniqueMembers(data);

            for(let i=0; i<data.length; i++){
                const member = data[i];

                if(member && member[0]){
                    member[0] = member[0].toString().trim();
                }

                if(member && Array.isArray(member) && regex.test(member[0])){
                    queue.push({
                        "address": member[0],
                        "name": member[1] ? member[1] : '',
                    })
                }
                else if(member){
                    if(validArray(member)){
                        status[member[0]] = {
                            status: 'Invalid Data',
                            id: 0,
                        };
                    }
                }
            }

            const result = await addMembers(queue, mailingList);

            if(result) return true;
            else return false;

        } catch (err) {
            console.log('Failed to import members, please try again!')
            // toastContext.showToast(
            //     'Failed to import members, please try again!',
            // );
            return false;
        }
    }

    return (
        <context.Provider
            value={{
                uploadToS3,
                uploadToS3v2,
                onImport,
                onMembersImport
            }}>
            {children}
        </context.Provider>
    )
}

Provider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Provider;