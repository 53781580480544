import React, { useRef, useState, } from 'react';

import {
    Center, Input, Spinner, Box, useDisclosure, Button
} from '@chakra-ui/react';

import { useUtils, useApp } from '../../context'
import Papa from 'papaparse';
import ImportSubscriberModal from './ImportSubscriberModal'

const ImportSubscriber = ({ userId }) => {
    const utils = useUtils();
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box>
            <Button
                size="sm"
                w={'100%'}
                variant="outline"
                colorScheme="black"
                onClick={() => {
                    onOpen()
                }}
                mt="4"
            >
                Import Subscribers
            </Button>
            <ImportSubscriberModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} userId={userId} />
        </Box>
    )
}

export default ImportSubscriber;
