import { useState, useEffect } from 'react';
import {
    Center,
    Box,
    Select,
    Flex,
    Button,
    Textarea,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useApp } from '../context';

const UpdatePlanTemplate = () => {
    const app = useApp();
    const toast = useToast();
    const [selectedPlan, setSelectedPlan] = useState('');
    const [plan, setPlan] = useState({});
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const getPlan = async selectPlanName => {
        console.log('PLAN NAME: ', selectPlanName);
        setSelectedPlan(selectPlanName);

        if (selectPlanName) {
            const findingPlan = options.find(
                o => o.plan_publicUrl === selectPlanName
            );
            setPlan(findingPlan);
            console.log(findingPlan);
        } else {
            setPlan({});
        }
    };

    const fetchOptions = async () => {
        let response = await app.helloFambase('GET', 'dashboard/planTemplates');
        if (response.success) {
            console.log(response);
            setOptions(response.data);
        }
    };

    useEffect(async () => {
        fetchOptions();
    }, []);

    const handleChange = e => {
        setPlan({
            ...plan,
            template: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await app.helloFambase(
                'PUT',
                'dashboard/update-plan-template',
                plan
            );
            if (res.success) {
                toast({
                    title: 'Success',
                    description: 'Plan template updated',
                    status: 'success',
                });
                setPlan({});
                setSelectedPlan('');
                fetchOptions();
            }
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error',
                description: 'Error updating plan template',
                status: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading)
        return (
            <Flex justify={'center'} mt={10}>
                <Spinner />
            </Flex>
        );

    return (
        <Flex direction="column" h="100%">
            <Flex align="center" width="100%">
                <Center>
                    <Select
                        placeholder="Select option"
                        width="100%"
                        onChange={e => getPlan(e.target.value)}
                        size="lg"
                    >
                        {options.map((option, index) => {
                            return (
                                <option
                                    key={index}
                                    value={option.plan_publicUrl}
                                >
                                    {option.plan_publicUrl}
                                </option>
                            );
                        })}
                    </Select>
                </Center>
            </Flex>
            {selectedPlan && (
                <>
                    <Textarea
                        mt={10}
                        style={{ flex: 1 }}
                        placeholder="Enter your message"
                        value={plan.template}
                        onChange={handleChange}
                    />
                    <Button
                        mt={5}
                        boxShadow="xl"
                        colorScheme="red"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </>
            )}
        </Flex>
    );
};

export default UpdatePlanTemplate;
