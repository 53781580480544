import React, { useState } from 'react';

import {
    Center,
    Button,
    useDisclosure,
} from '@chakra-ui/react';

import { useApp } from '../../context';
import SubscribedToModal from './SubscribedToModal';

const SubscribedTo = ({ userId }) => {
    const app = useApp();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Center mt="4">
            <Button
                size="sm"
                w={'100%'}
                variant="outline"
                colorScheme="black"
                onClick={() => {
                    onOpen()
                }}
            >
                Subscribed to
            </Button>
            <SubscribedToModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} userId={userId} />

        </Center>
    );
};

export default SubscribedTo;
