import React from 'react';
import {
  ChakraProvider,
  theme,
  extendTheme,
} from '@chakra-ui/react';


import { AuthProvider, AppProvider, UtilsProvider } from "./context";

import Main from './Main';


const extendedTheme = extendTheme({
  components: {
    Divider: {
      baseStyle: {
        opacity: 1,
        borderColor: "black"
      }
    }
  }
});

function App() {
  return (
    <ChakraProvider theme={extendedTheme}>
      <AuthProvider>
        <AppProvider>
          <UtilsProvider>
            <Main />
          </UtilsProvider>
        </AppProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
// dummy push