import React, { useState } from 'react';

import {
    Box,
    Flex,
    Input,
    Text,
    Button,
    Spinner
} from '@chakra-ui/react';

import { useApp } from '../../context';

const RuleSet = () => {
    const [userId, setUserId] = useState(0);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, showError] = useState(false);
    const [success, setSuccess] = useState(false);

    const app = useApp();

    const userIdChangeHandler = (e) => {
        setUserId(e.target.value);
    }

    const usernameChangeHandler = (e) => {
        setUsername(e.target.value);
    }

    const submitHandler = async () => {
        showError(false);
        setSuccess(false);
        setLoading(true);

        const data = {
            userId: userId,
            username: username,
            email: email
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/create-mailgun-rule-set',
            data
        )
        console.log(response);
        setLoading(false);

        if(response.success){
            setSuccess(true);
        }
        else showError(true);
    }

    return(
        <Box mt="20" ml="20" mr="40" height="100%">
            <Flex>
                {/* <Flex direction="column" mr="10">
                    <Text size="md" mb="8px">Enter user id or username of the receiver</Text>
                    <Input onChange={userIdChangeHandler} placeholder="User Id / Username" size="md" />
                </Flex> */}
                <Flex direction="column">
                    <Text size="md" mb="8px">Enter username</Text>
                    <Input onChange={usernameChangeHandler} placeholder="username" size="md" />
                </Flex>
            </Flex>

            <Flex mt="10" direction="column">
                <Text size="md" mb="8px">Enter email to forward to</Text>
                <Input onChange={(e) => setEmail(e.target.value)} placeholder="email" size="md" />
            </Flex>

            <Button size="md" mt="10" colorScheme="red" onClick={submitHandler}>
                {loading ? <Spinner /> : "Submit"}
            </Button>

            { error && <Text size="md" mb="8px" mt="10" color="red">Error Occurred</Text> } 
            { success && <Text size="md" mb="8px" mt="10" color="green">Rule Set created successfully</Text> } 
        </Box>
    )
}

export default RuleSet;