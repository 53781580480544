import React from 'react';

import { Redirect } from 'react-router-dom';
import { Search } from '../components/ui-molecules';

import { Dashboard } from '../layouts';

import {
    TriggerRefunds,
    SearchUsers,
    InterestedUsers,
    CreatorAnalytics,
    SearchContent,
    DynamicReports,
    Notification,
    Email,
    ListEmails,
    MochaTests,
    MigrateContent,
    MailingLists,
    MailTemplates,
    WhatsappTemplates,
    Acquisition,
    ReportedItems,
    AssetsUploader,
    AddCreator,
    CertificateGenerator,
    CashBack,
    Referrals,
    WorkshopMnt,
    Whatsapp,
    SendTemplateMail,
    ProjectEvaluations,
    TransferSubscription,
    Revalidate,
    PlanMnt,
    ZoomMeetings,
    FeedbackReports,
    UserTracker,
    UpdatePlanTemplate,
    AddHiringPartner,
    AddUpdateJobs,
    AdhocNotifications,
    Modals,
    FambaseExportData,
    GenerateImage,
    ReviewImages,
    PollResults,
    CouponCodes,
} from '../views';

export default [
    {
        path: '/',
        exact: true,
        name: 'Dashboard',
        component: () => <Redirect to="/dashboard" />,
    },
    // {
    //     path: '/dashboard/triggerRefunds',
    //     name: 'Trigger Refunds',
    //     component: TriggerRefunds,
    // },
    {
        path: '/dashboard/searchUsers',
        name: 'Search Users',
        component: SearchUsers,
    },
    // {
    //     path: '/dashboard/userTracker',
    //     name: 'User Tracker',
    //     component: UserTracker,
    // },
    // {
    //     path: '/dashboard/addCreator',
    //     name: 'Add Creator',
    //     component: AddCreator,
    // },
    // {
    //     path: '/dashboard/searchContent',
    //     name: 'Search Content',
    //     component: SearchContent,
    // },
    // {
    //     path: '/dashboard/interestedUsers',
    //     name: 'Interested Users',
    //     component: InterestedUsers,
    // },
    // {
    //     path: '/dashboard/creatorAnalytics',
    //     name: 'Creator Analytics',
    //     component: CreatorAnalytics,
    // },
    {
        path: '/dashboard/dynamicReports',
        name: 'Dynamic Reports',
        component: DynamicReports,
    },
    {
        path: '/dashboard/fambaseExportData',
        name: 'Fambase Export Data',
        component: FambaseExportData,
    },
    {
        path: '/dashboard/generateImage',
        name: 'Generate Image',
        component: GenerateImage,
    },
    {
        path: '/dashboard/addHiringPartner',
        name: 'Add Hiring Partner',
        component: AddHiringPartner,
    },
    {
        path: '/dashboard/addUpdateJobs',
        name: 'Add/Update Jobs',
        component: AddUpdateJobs,
    },
    // {
    //     path: '/dashboard/reports',
    //     name: 'Reported Items',
    //     component: ReportedItems,
    // },
    // {
    //   path: '/dashboard/notification',
    //   name: 'Send Notification',
    //   component: Notification,
    // },
    {
        path: '/dashboard/email',
        name: 'Send Email',
        component: Email,
    },
    {
        path: '/dashboard/whatsapp',
        name: 'Send WhatsApp',
        component: Whatsapp,
    },
    {
        path: '/dashboard/sendTemplateMail',
        name: 'Send Template Mail',
        component: SendTemplateMail,
    },
    // {
    //   path: '/dashboard/listEmails',
    //   name: 'List of Scheduled Mails',
    //   component: ListEmails,
    // },
    // {
    //     path: '/dashboard/mochaTest',
    //     name: 'Mocha Tests',
    //     component: MochaTests,
    // },
    // {
    //     path: '/dashboard/migrateContent',
    //     name: 'Migrate Content',
    //     component: MigrateContent,
    // },
    {
        path: '/dashboard/mailingLists',
        name: 'Mailing Lists',
        component: MailingLists,
    },
    {
        path: '/dashboard/mailTemplates',
        name: 'Mail Templates',
        component: MailTemplates,
    },
    {
        path: '/dashboard/whatsappTemplates',
        name: 'Whatsapp Templates',
        component: WhatsappTemplates,
    },
    // {
    //     path: '/dashboard/modals',
    //     name: 'Generic Modals',
    //     component: Modals,
    // },
    // {
    //     path: '/dashboard/acquisition',
    //     name: 'Acquisition',
    //     component: Acquisition,
    // },
    // {
    //     path: '/dashboard/assetsUploader',
    //     name: 'Assets Uploader',
    //     component: AssetsUploader,
    // },
    {
        path: '/dashboard/certificate-generator',
        name: 'Attendance & Certificate',
        component: CertificateGenerator,
    },
    // {
    //     path: '/dashboard/cashback',
    //     name: 'Send Cashback Payout',
    //     component: CashBack,
    // },
    // {
    //     path: '/dashboard/generic-referrals',
    //     name: 'Generic Referrals',
    //     component: Referrals,
    // },
    // {
    //     path: '/dashboard/workshop-management',
    //     name: 'Workshop Management',
    //     component: Workshop,
    // },
    {
        path: '/project-evaluations',
        name: 'Project Evaluations',
        component: ProjectEvaluations,
    },
    // {
    //     path: '/transfer-subscription',
    //     name: 'Transfer Subscription',
    //     component: TransferSubscription,
    // },

    // {
    //     path: '/workshop-management',
    //     name: 'Workshop Management',
    //     component: WorkshopMnt,
    // },
    // {
    //     path: '/zoom-meetings',
    //     name: 'Zoom Meetings',
    //     component: ZoomMeetings,
    // },
    // {
    //     path: '/revalidate',
    //     name: 'Revalidate',
    //     component: Revalidate,
    // },
    // {
    //     path: '/plan-management',
    //     name: 'Plan Management',
    //     component: PlanMnt,
    // },
    // {
    //     path: '/feedback-reports',
    //     name: 'Feedback Reports',
    //     component: FeedbackReports,
    // },
    {
        path: '/update-plan-template',
        name: 'Update Plan Template',
        component: UpdatePlanTemplate,
    },
    // {
    //     path: '/adhoc-notifications',
    //     name: 'Send adhoc notifications',
    //     component: AdhocNotifications,
    // },
    // {
    //     path: '/image-reviewer',
    //     name: 'Review Images',
    //     component: ReviewImages,
    // },
    // {
    //     path: '/workshop-poll-results',
    //     name: 'Workshop Poll Results',
    //     component: PollResults,
    // },
    // {
    //     path: '/coupon-codes',
    //     name: 'Coupon Codes',
    //     component: CouponCodes,
    // },
];
