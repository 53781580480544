import React, { useState, useEffect, useRef } from 'react';
import { useApp } from '../../context';

import {
    CircularProgress,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Box,
    Button,
    Flex,
    Select,
    Input
} from '@chakra-ui/react';

import {
    TriangleDownIcon
} from '@chakra-ui/icons';

import { SinglePagePagination } from '../../components/ui-molecules';
import papaparse from 'papaparse';

const TH = ({ children }) => {
    return <Th textAlign="center">{children}</Th>;
};

const TD = ({ children }) => {
    return <Td textAlign="center">{children}</Td>;
};

const ReportTable = ({ isOpen, onOpen, onClose, userId, currentType }) => {
    const app = useApp();
    const modalRef = useRef();
    const [dataObject, setDataObject] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [searching, setSearching] = useState(false);
    const [columns, setColumns] = useState([]);
    const [filterData, setFilterData] = useState({
        column: '',
        value: '',
        type: '',
    });
    const [sortData, setSortData] = useState({
        column: '',
        order: 'asc',
    });

    const filterDataColumnRef = useRef();
    const filterDataValueRef = useRef();

    const getStats = async (freshData=false, reset=false) => {
        console.log('in get stats ', currentType);

        setSearching(true);
        if(reset) {
            setFilterData({column: '', value: '', type: ''});
            filterDataColumnRef.current.value = '';
            filterDataValueRef.current.value = '';
        }
        console.log('FILTER DATA ', filterData);

        let body = {
            key: currentType,
            filterData: reset ? {} : filterData,
            sortData: sortData,
            limit: limit,
            offset: offset,
        };

        let response = await app.helloFambase(
            'POST',
            'dashboard/dynamicReports',
            body
        );

        if (response.success) {
            console.log('REPORT DATA ', response.data.data);
            let cols = response.data.headers.toString().split(',');
            setColumns(cols);

            if(freshData) setDataObject(response.data.data);
            else setDataObject([...dataObject, ...response.data.data]);
            setSearching(false);
        }
    };

    useEffect(async () => {
        setCurrentData(
            dataObject.slice(20 * (pageNumber - 1), 20 * pageNumber)
        );
    }, [dataObject, pageNumber]);

    useEffect(() => {
        if (pageNumber * 20 >= dataObject.length) {
            getStats();
        }
    }, [offset]);

    useEffect(() => {
        getStats(true);
    }, [sortData])

    const exportList = async () => {
        let body = {
            key: currentType,
            filterData: filterData,
            sortData: sortData,
            limit: 100000,
            offset: 0,
        };

        let res = await app.helloFambase(
            'POST',
            'dashboard/dynamicReports',
            body
        );

        if (res.success) {
            const downloadData = [];
            let cols = res.data.headers.toString().split(',');
            downloadData.push(cols);

            for (let i = 0; i < res.data.data.length; i += 1) {
                let temp = [];
                for (let j = 0; j < cols.length; j += 1) {
                    temp.push(res.data.data[i][cols[j]]);
                }
                downloadData.push(temp);
            }

            let csvString = papaparse.unparse(downloadData);
            csvString = `data:text/csv;charset=utf-8,${csvString}`;

            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csvString));

            const today = new Date();

            let fileName = `${today.toLocaleDateString()}_mail_statistics`;
            if(filterData.column && filterData.value && filterData.type) {
                fileName += '_filtered-column-'+filterData.column+'_type-'+filterData.type+'_value-'+filterData.value;
            }

            fileName += '.csv';

            
            link.setAttribute(
                'download',
                fileName
            );
            link.click();
        }
    };

    return (
        <Box h="100%">
            <Flex mt="5" mb="1" justify={'space-between'} align={'center'}>
                <Flex>
                    <Select ref={filterDataColumnRef} placeholder='Select Filter Column' size='md' onChange={(e) => setFilterData({...filterData, column: e.target.value})}>
                        {columns.map((col, index) => {
                            return (
                                <option key={index} value={col}>
                                    {col}
                                </option>
                            );
                        })}
                    </Select>

                    <Select ref={filterDataValueRef} ml="2" placeholder='Search Type' size='md' onChange={(e) => setFilterData({...filterData, type: e.target.value})}>
                        <option value='='>Equals</option>
                        <option value='!='>Not Equals</option>
                        <option value='like'>Contains</option>
                        <option value='not like'>Does not Contains</option>
                        <option value='>'>Greater Than</option>
                        <option value='<'>Less Than</option>
                    </Select>

                    <Input
                        placeholder='Search Value'
                        size='md'
                        ml='2'
                        value={filterData.value}
                        onChange={(e) => setFilterData({...filterData, value: e.target.value})}
                    />

                    <Button
                        size="md"
                        width="150px"
                        colorScheme="linkedin"
                        onClick={() => {
                            if(!filterData.column || !filterData.value || !filterData.type) {
                                alert('Please select a column and value and type to filter');
                                return;
                            }
                            getStats(true);
                        }}
                        ml='2'
                    >
                        Filter
                    </Button>

                    <Button
                        size="md"
                        width="150px"
                        colorScheme="red"
                        onClick={() => {
                            getStats(true, true);
                        }}
                        ml='2'
                    >
                        Reset
                    </Button>
                </Flex>

                <Button
                    size="md"
                    colorScheme="linkedin"
                    onClick={() => {
                        exportList();
                    }}
                    disabled={currentData.length === 0}
                >
                    Export
                </Button>
            </Flex>
            <Text mb="3">Search value is case sensitive*</Text>

            {currentData.length && !searching > 0 ? (
                <Box
                    h="80%"
                    overflow="scroll"
                    mb="5"
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '0px',
                        },
                    }}
                >
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                {columns.map((col, index) => {
                                    return (
                                        <TH key={index}>{col} 
                                            <TriangleDownIcon 
                                                onClick={() => setSortData({...sortData, column: col, order: sortData.order === 'asc' ? 'desc' : 'asc'})} 
                                                style={{
                                                    cursor: 'pointer',
                                                    transform: (sortData.column === col && sortData.order === 'asc') ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    color: sortData.column === col ? 'red' : 'grey',
                                                    marginLeft: '5px'
                                                }} 
                                            />
                                        </TH>
                                    );
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentData.map((data, index) => {
                                return (
                                    <Tr key={index}>
                                        {columns.map((col, index) => {
                                            return (
                                                <TD key={index}>
                                                    {data[col.trim()]}
                                                </TD>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
            ) : (
                <Center h="80%">
                    {searching ? (
                        <CircularProgress isIndeterminate color="red.400" />
                    ) : (
                        <Text>No more report data</Text>
                    )}
                </Center>
            )}

            <SinglePagePagination
                pageNumber={pageNumber}
                onLeftClick={() => {
                    if (pageNumber > 1) {
                        setPageNumber(pageNumber - 1);
                    }
                }}
                onRightClick={() => {
                    if (currentData.length > 0) {
                        setOffset(offset + 20);
                        setPageNumber(pageNumber + 1);
                    }
                }}
            />
        </Box>
    );
};

export default ReportTable;
