import React, { useState, useEffect } from 'react';
import { useApp } from '../../context';
import { Link } from 'react-router-dom'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CircularProgress,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Badge,
    Avatar
} from '@chakra-ui/react';

import { SinglePagePagination } from '../../components/ui-molecules';


const TH = ({ children }) => {
    return <Th textAlign="center">{children}</Th>;
};

const TD = ({ children }) => {
    return <Td textAlign="center">{children}</Td>;
};

const SubscribedToModal = ({ isOpen, onOpen, onClose, userId }) => {
    const app = useApp();
    const [subscribedToObject, setSubscribedToObject] = useState([]);
    const [currentSubscribedTo, setCurrentSubscribedTo] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [searching, setSearching] = useState(false);

    const getPosts = async () => {
        setSearching(true)
        let body = {
            userId: userId,
            limit: limit,
            offset: offset
        }

        let response = await app.helloFambase(
            'POST',
            'dashboard/subscribedTo',
            body
        );

        console.log("RESPONSE SUBSCRIBED", response)
        if (response.success) {
            setSubscribedToObject([...subscribedToObject, ...response.data])
            setSearching(false)
        }
    }

    useEffect(async () => {
        setCurrentSubscribedTo(subscribedToObject.slice(20 * (pageNumber - 1), 20 * pageNumber))
    }, [subscribedToObject, pageNumber]);

    useEffect(() => {
        console.log(subscribedToObject)
        if (pageNumber * 20 >= subscribedToObject.length) {
            getPosts();
        }
    }, [offset])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior={!searching && "inside"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>They are subscribed to</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {currentSubscribedTo.length && !searching > 0 ? (
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <TH>Creator Picture</TH>
                                    <TH>Creator Id</TH>
                                    <TH>Creator name</TH>
                                    <TH>Subscription Period</TH>
                                    <TH>Subscription Amount</TH>
                                    <TH>Subscription Renewed on</TH>
                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    currentSubscribedTo.map((creator, index) => {
                                        return (
                                            <Tr key={index}>
                                                <TD> <Avatar
                                                    name={creator.name}
                                                    src={creator.picture}
                                                    boxSize="40px"
                                                /></TD>
                                                <TD>{creator.cu_id}</TD>
                                                <TD>{creator.name}</TD>
                                                <TD>{creator.period}</TD>
                                                <TD>{creator.amount}</TD>
                                                <TD>{creator.nextChargeOn}</TD>
                                            </Tr>
                                        )
                                    })
                                }

                            </Tbody>
                        </Table>
                    ) : (
                        <Center h="100%">
                            {searching ? (
                                <CircularProgress isIndeterminate color="red.400" />
                            ) : (
                                <Text>No more content</Text>
                            )}
                        </Center>
                    )}
                </ModalBody>
                <SinglePagePagination
                    pageNumber={pageNumber}
                    onLeftClick={() => {
                        if (pageNumber > 1) {
                            setPageNumber(pageNumber - 1);
                        }
                    }}
                    onRightClick={() => {
                        if (currentSubscribedTo.length > 0) {
                            setOffset(offset + 20);
                            setPageNumber(pageNumber + 1);
                        }
                    }}
                />
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SubscribedToModal;
