import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './routing/PrivateRoute';

import Routes from './routing/routes';

import { Dashboard } from './layouts';

import { Login } from './views';

const Main = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Dashboard>
            {Routes.map((route, index) => {
              return (
                <PrivateRoute key={index} path={route.path}>
                  <route.component />
                </PrivateRoute>
              );
            })}
          </Dashboard>
        </Switch>
      </Router>
    </>
  );
};

export default Main;
