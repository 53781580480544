import React, { useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import { Heading, Button, Flex, useToast } from '@chakra-ui/react';

import { PlainLayout } from '../layouts';

import { GoogleLogin } from 'react-google-login';
import { googleAppId } from '../config';

import { useAuth, useApp } from '../context';

const Login = () => {
  const app = useApp();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: '/dashboard' } };

  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const statuses = ['success', 'error', 'warning', 'info'];

  const loginWithGoogle = async response => {
    let name = '';
    let uPic = '';

    if (response.profileObj && response.profileObj.imageUrl) {
      uPic = response.profileObj.imageUrl;
    }

    if (response.profileObj) {
      name = response.profileObj.name
        ? response.profileObj.name
        : `${response.profileObj.givenName}${
            response.profileObj.familyName
              ? ' ' + response.profileObj.familyName
              : ''
          }`;
    }

    const tempPayload = {
      email: response.profileObj.email,
      type: 'G',
      dashboard: true,
      loginToken: response.tokenObj.id_token,
      name: name,
      uPic: uPic,
    };

    let fambaseResponse = await app.helloFambase(
      'POST',
      'auth/login',
      tempPayload
    );

    if (fambaseResponse.data.data.userDetail.roles === 'admin' || fambaseResponse.data.data.userDetail.subRoles === "instructor" || fambaseResponse.data.data.userDetail.subRoles === "reports" || fambaseResponse.data.data.userDetail.subRoles === "image-reviewer") {
      const payload = {
        user: fambaseResponse.data.data.userDetail.email,
        userToken: fambaseResponse.data.data.auth,
        roles: fambaseResponse.data.data.userDetail.roles,
        subRoles: fambaseResponse.data.data.userDetail.subRoles
      };
      
      const callback = () => {
        history.replace(from);
      };

      auth.signIn(callback, payload);
    } else {
      setIsLoading(false);
      toast({
        title: `Need admin role to access the dashboard.`,
        status: 'error',
        isClosable: true,
      });
    }
  };

  if (auth.userToken) {
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
        }}
      />
    );
  }

  return (
    <PlainLayout>
      <Flex direction="column" align="center">
        <Heading m={10}>TTT Academy Dashboard</Heading>
        <GoogleLogin
          clientId={googleAppId}
          render={renderProps => (
            <Button
              isLoading={isLoading}
              loadingText="logging in..."
              onClick={() => {
                setIsLoading(true);
                renderProps.onClick();
              }}
              boxShadow="xl"
              colorScheme="red"
              w="50%"
            >
              Log In with Google
            </Button>
          )}
          onSuccess={loginWithGoogle}
          onFailure={response => {
            setIsLoading(false);
            toast({
              title: `Sorry, could'nt login. Please try again.`,
              status: 'error',
              isClosable: true,
            });
          }}
          cookiePolicy={'single_host_origin'}
        />
      </Flex>
    </PlainLayout>
  );
};

export default Login;
