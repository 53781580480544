import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { IconButton, Flex, Spacer, Text } from '@chakra-ui/react';

const SinglePagePagination = ({ pageNumber, onLeftClick, onRightClick }) => {
  return (
    <Flex w="10rem" margin="auto">
      <IconButton
        variant="outline"
        colorScheme="red"
        aria-label="Call Sage"
        fontSize="20px"
        icon={<ChevronLeftIcon />}
        onClick={onLeftClick}
      />
      <Spacer />
      <Text my="auto">{pageNumber}</Text>
      <Spacer />
      <IconButton
        variant="outline"
        colorScheme="red"
        aria-label="Call Sage"
        fontSize="20px"
        icon={<ChevronRightIcon />}
        onClick={onRightClick}
      />
    </Flex>
  );
};

export default SinglePagePagination;
