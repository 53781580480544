import React, { useState, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Flex,
    Text,
    Input,
} from '@chakra-ui/react';

const JobTable = ({ companies, handleEditJob }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [paginatedJobs, setPaginatedJobs] = useState([]);
    const [companyArray, setCompanyArray] = useState([]);

    useEffect(() => {
        const updatedCompanyArray = Array.isArray(companies)
            ? companies
            : [companies];
        setCompanyArray(updatedCompanyArray);
        const filteredJobs = updatedCompanyArray.flatMap(company =>
            company.jobs.filter(job =>
                job.jobTitle.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredJobs(filteredJobs);
        setTotalPages(Math.ceil(filteredJobs.length / itemsPerPage));
        setStartIndex((currentPage - 1) * itemsPerPage);
        setEndIndex(startIndex + itemsPerPage);
        setPaginatedJobs(filteredJobs.slice(startIndex, endIndex));
    }, [
        companies,
        searchQuery,
        currentPage,
        itemsPerPage,
        startIndex,
        endIndex,
    ]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            {companyArray.length === 0 ? (
                <Text>No companies available</Text>
            ) : (
                <>
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        gridGap="2rem"
                    >
                        <Text fontSize="md">Search by job title:</Text>
                        <Input
                            type="text"
                            placeholder="Search by job title"
                            value={searchQuery}
                            w="40rem"
                            onChange={handleSearchChange}
                            m="3rem 0 3rem 0"
                            p="1rem 3rem"
                        />
                    </Flex>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Company Name</Th>
                                <Th>Job Title</Th>
                                <Th>Edit</Th>
                            </Tr>
                        </Thead>
                        {companyArray.map(company => (
                            <Tbody key={company.companyId}>
                                {paginatedJobs.length === 0 ? (
                                    <Tr>
                                        <Td colSpan={3}>
                                            No jobs available
                                        </Td>
                                    </Tr>
                                ) : (
                                    paginatedJobs.map(job => (
                                        <Tr key={job.jobId}>
                                            <Td>{company.companyName}</Td>
                                            <Td>{job.jobTitle}</Td>
                                            <Td>
                                                <Button
                                                    colorScheme="blue"
                                                    onClick={() =>
                                                        handleEditJob(
                                                            job,
                                                            company
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))
                                )}
                            </Tbody>
                        ))}
                    </Table>

                    {filteredJobs.length > itemsPerPage && (
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            mt="2rem"
                        >
                            {Array.from(
                                { length: totalPages },
                                (_, index) => index + 1
                            ).map(page => (
                                <Button
                                    key={page}
                                    colorScheme={
                                        currentPage === page ? 'blue' : 'gray'
                                    }
                                    onClick={() => handlePageChange(page)}
                                    disabled={currentPage === page}
                                    mx="0.5rem"
                                >
                                    {page}
                                </Button>
                            ))}
                        </Flex>
                    )}
                </>
            )}
        </>
    );
};

export default JobTable;
